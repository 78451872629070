<template>
    <v-layout v-resize="onResize" class="d-flex flex-column">
        <v-sheet color="white" class="px-4 rounded-t">
            <div class="d-flex align-center pt-2 pb-4">
                <div class="d-flex align-start mr-4">
                    <v-icon x-large color="primary" >
                        {{ tableIcon }}
                    </v-icon>
                </div>
                <span class="text-h6 font-weight-regular">{{ `${tableName}${event ? ' - ' + event.text : ''}` }}</span>

                <v-spacer></v-spacer>

                <!-- <v-tooltip top v-if="selectedRowId !== null && !Array.isArray(selectedRowId) && !isShowingFormulas"> -->
                <v-tooltip top v-if="false">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            color="secondary"
                            class="mr-4 pa-0"
                            v-on="on"
                            @click="openDialogRatio()"
                            min-width="48px"
                            :disabled="disableActionButtons"
                        >
                        <v-icon>mdi-clipboard-flow</v-icon>
                        </v-btn>
                    </template>
                    <span>Executar rateios</span>
                </v-tooltip>

                <v-dialog v-if="dialogRatio"
                    v-model="dialogRatio"
                    max-width="1200"
                    style="z-index: 1000"
                >
                    <v-card style="position: relative">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5"
                            elevation="0"
                        >
                        <v-toolbar-title>
                            <span>Executar rateios </span>
                        </v-toolbar-title>
                        <v-spacer />

                        <v-tooltip bottom>
                            <template v-slot:activator="on">
                            <v-btn
                                color="transparent"
                                class="mt-2"
                                v-on="on"
                                min-width="48px"
                                @click="dialogRatio = false"
                                depressed
                            >
                                <v-icon class="white--text">mdi-close</v-icon>
                            </v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>
                        </v-toolbar>
                        <v-container fluid class="ma-0 pa-8">
                            <!-- <h1>{{ `editRatio: ${editRatio}` }}</h1> -->
                            <!-- <p>{{ `selectedRow: ${JSON.stringify(selectedRow)}` }}</p> -->

                            <v-row>
                                <v-col cols="6">
                                    <v-sheet elevation="3"
                                        style="border-radius: 12px !important; height: 100%;"
                                    >
                                        <v-data-table
                                            :headers="[
                                                { text: 'Descrição', value: 'description' },
                                                { text: 'Código', value: 'cd_cost_center' },
                                                { text: '', value: 'actions' }
                                            ]"
                                            :items="ratioCostCenterItems"
                                            item-key="id"
                                            fixed-header
                                            :hide-default-footer="ratioCostCenterItems.length < 10 ? true : false"
                                            :search="searchRatio"
                                        >
                                            <template v-slot:top>
                                                <v-toolbar flat style="border-radius: 12px;">
                                                    <v-col cols="3">
                                                        <span class="text-subtitle-1">Disponíveis</span>
                                                    </v-col>

                                                    <v-col cols="9" class="d-flex pa-0 align-center">
                                                        <v-text-field
                                                            v-model="searchRatio"
                                                            append-icon="mdi-magnify"
                                                            :label="$vuetify.lang.t('$vuetify.searchLabel')"
                                                            hide-details
                                                            single-line
                                                            dense
                                                            clearable
                                                            class="mr-2"
                                                        />
                                                        <v-spacer></v-spacer>
                                                        <v-btn class="primary"
                                                            @click="selectAllRatioCostCenterItems()"
                                                        >
                                                            <v-icon color="white">mdi-chevron-double-right</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-toolbar>
                                            </template>
                                            <!-- eslint-disable-next-line -->
                                            <template v-slot:item.actions="{ item, index }">
                                                <div class="w-100 d-flex justify-end">
                                                    <v-btn icon color="primary"
                                                        @click="selectRatioCostCenterItem(item, index)"
                                                    >
                                                        <v-icon>mdi-chevron-right</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-sheet>
                                </v-col>

                                <v-col cols="6">
                                    <v-sheet elevation="3"
                                        style="border-radius: 12px !important; height: 100%;"
                                    >
                                        <v-data-table
                                            :headers="[
                                                { text: 'Descrição', value: 'description' },
                                                { text: 'Código', value: 'cd_cost_center' },
                                                { text: '', value: 'actions' }
                                            ]"
                                            :items="selectedRatioCostCenterItems"
                                            item-key="id"
                                            fixed-header
                                            :hide-default-footer="selectedRatioCostCenterItems.length < 10 ? true : false"
                                            :search="searchSelectedRatio"
                                        >
                                            <template v-slot:top>
                                                <v-toolbar flat style="border-radius: 12px;">
                                                    <v-col cols="3">
                                                        <span class="text-subtitle-1">Selecionados</span>
                                                    </v-col>

                                                    <v-col cols="9" class="d-flex pa-0 align-center">
                                                        <v-text-field
                                                            v-model="searchSelectedRatio"
                                                            append-icon="mdi-magnify"
                                                            :label="$vuetify.lang.t('$vuetify.searchLabel')"
                                                            hide-details
                                                            single-line
                                                            dense
                                                            clearable
                                                            class="mr-2"
                                                        />
                                                        <v-spacer></v-spacer>
                                                        <v-btn class="primary"
                                                            @click="removeAllRatioCostCenterItems()"
                                                        >
                                                            <v-icon color="white">mdi-chevron-double-left</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-toolbar>
                                            </template>

                                            <!-- eslint-disable-next-line -->
                                            <template v-slot:item.actions="{ item, index }">
                                                <div class="w-100 d-flex justify-end">
                                                    <v-btn icon color="primary"
                                                        @click="removeRatioCostCenterItem(item, index)"
                                                    >
                                                        <v-icon>mdi-chevron-left</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </v-sheet>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-dialog>

                <v-tooltip top v-if="selectedRowId !== null && !Array.isArray(selectedRowId) && !isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="duplicateItem()"
                        >
                            <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                    </template>
                    <span>Duplicar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="selectedRowId !== null && !Array.isArray(selectedRowId) && !isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="editItem()"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar item selecionado</span>
                </v-tooltip>

                <v-tooltip top v-if="selectedRowId !== null && !isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="secondary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="dialogMassDelete = true"
                        >
                            <v-icon>mdi-delete-sweep</v-icon>
                        </v-btn>
                    </template>
                    <span>Deletar itens selecionados</span>
                </v-tooltip>

                <v-dialog v-model="dialogMassDelete" max-width="600px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline text-center">Tem certeza de que deseja excluir estes itens?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogMassDelete = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="massDeleteItemConfirm" :loading="deleting">Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top v-if="!isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges || !selectedPlanningGroup"
                            @click="createItem()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Novo item</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="renew()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-file-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Renovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRenewGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRenewGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="editedRows = [], renew()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="renew(save = true)" :loading="saving">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || event.id_event_type === 4 || event.id_event_type === 1"
                            @click="updateMonthsDialog = true"
                        >
                            <v-icon>mdi-update</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar meses realizados</span>
                </v-tooltip>

                <v-dialog v-model="updateMonthsDialog" max-width="450px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="title">Você deseja realizar essa atualização?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="updateMonthsDialog = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="updateMonths()" :loading="loadingUpdateMonths">Continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="refresh()"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </template>
                    <span>Atualizar</span>
                </v-tooltip>

                <v-dialog v-model="dialogRefreshGrid" max-width="850px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogRefreshGrid = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="secondary" text @click="editedRows = [], refresh()" class="mr-4">Continuar sem salvar</v-btn>
                            <v-btn color="primary" @click="refresh(save = true)" :loading="saving">Salvar e continuar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-tooltip top v-if="!isShowingFormulas">
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-4 pa-0"
                            @click="saveData()"
                            v-on="on"
                            min-width="48px"
                            :color="unsavedChanges ? 'secondary' : 'primary'"
                            :disabled="disableActionButtons || readOnly || !unsavedChanges || isShowingFormulas"
                            :loading="saving"
                        >
                            <v-icon> {{ unsavedChanges || isShowingFormulas ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>Salvar</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || readOnly || unsavedChanges"
                            @click="importSheet()"
                            :loading="importing"
                        >
                            <v-icon>mdi-file-excel</v-icon>
                        </v-btn>
                        <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                        >
                    </template>
                    <span>Importar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons || unsavedChanges"
                            @click="exportSheet()"
                            :loading="exporting"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>Exportar Excel</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="drawer = true"
                            :disabled="disableActionButtons || hasMultipleSelection || unsavedChanges || hasEmptyRequiredFilter || !selectedPlanningGroup || planningGroup.length > 1"
                        >
                            <v-icon>mdi-comment-text-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Comentários</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApproval = true"
                            :disabled="disableActionButtons || hasMultipleSelection || unsavedChanges || !selectedPlanningGroup"
                        >
                            <v-icon>mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar</span>
                </v-tooltip>

                <v-dialog v-model="dialogApproval" max-width="800" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title v-if="actualLevel !== null">
                                <span v-if="actualLevel.id_level > 0 && actualLevel.id_status !== 4"> Aprovar nível - {{ actualLevel.id_level }} </span>
                                <span v-else-if="actualLevel.id_status === 4"> Todos os níveis aprovados </span>
                                <span v-else> Nenhum nível cadastrado </span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="dialogApproval = false, justification = ''"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-end wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="selectedEvent"
                                        :items="options.find(option => option.column === 'id_event').items"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="selectedPlanningGroup"
                                        :items="planningGroups"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Grupo de planejamento"
                                        persistent-hint
                                    ></v-select>
                                    <v-select
                                        v-model="product[0]"
                                        :items="loadingProduct ? [] : productOption.items"
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"
                                        label="Contrato"
                                        readonly
                                        persistent-hint
                                    ></v-select>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-btn class="my-2 mr-2" color="error" @click="approvalSelect(0)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_level === 1 && actualLevel.id_status !== 4">
                                    Não aprovar
                                </v-btn>
                                <v-btn class="my-2" color="success" @click="approvalSelect(1)" :loading="loadingApproval" :disabled="disableApprove || actualLevel.id_status === 4">
                                    Aprovar
                                </v-btn>
                                <v-flex>
                                    <v-data-table
                                        :headers="headers"
                                        :items="showHistory === true ? approvals : levels"
                                        :loading="loading"
                                        item-key="id_dynamic_report"
                                        sort-by="Usuário"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.id_status="{ item }">
                                            <v-layout>
                                                <v-icon
                                                    class="ml-4"
                                                    :color="item.id_status === 4 ? 'green' : 'red'"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                            <v-card-actions class="px-6 py-6">
                                <v-checkbox
                                    v-model="showHistory"
                                    label="Exibir o Histórico de todas as Aprovações e Reprovações"
                                ></v-checkbox>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-dialog>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            @click="dialogApprovalAll = true"
                            :disabled="disableActionButtons || hasMultipleSelection || allActualLevels.length < 1"
                        >
                            <v-icon>mdi-checkbox-multiple-marked-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Aprovar múltiplos</span>
                </v-tooltip>

                <v-dialog v-model="dialogApprovalAll" max-width="900" max-height="450" style="z-index: 1000;">
                    <v-card style="position: relative;">
                        <v-toolbar
                            dark
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                            style="position: sticky; top: 0; width: 100%; z-index: 5;" 
                            elevation="0"
                        >
                            <v-toolbar-title>
                                <span> Aprovar múltiplos </span>
                            </v-toolbar-title>
                            <v-spacer />

                            <v-tooltip bottom>
                                <template v-slot:activator="on">
                                    <v-btn color="transparent" class="mt-2"
                                        v-on="on"
                                        min-width="48px"
                                        @click="closeDialogApprovalAll()"
                                        depressed
                                    >
                                        <v-icon class="white--text">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-toolbar>
                        <v-container>
                            <v-layout justify-space-between wrap>
                                <v-flex xs12>
                                    <v-select
                                        v-model="event"
                                        :items="eventOption.items"
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Evento"
                                        @change="selectEvent()"
                                        return-object
                                        persistent-hint
                                        readonly
                                    ></v-select>
                                    <v-select
                                        v-model="selectedPlanningGroup"
                                        :items="planningGroups"
                                        readonly
                                        class="mx-2 my-1"
                                        item-text="text"
                                        item-value="id"               
                                        label="Grupo de planejamento"
                                        persistent-hint
                                    ></v-select>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="justification"
                                        class="mx-2 mt-1"
                                        label="Justificativa"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6>
                                    <v-menu
                                        top
                                        close-on-click
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-card class="mr-1" elevation="0" color="transparent">
                                                <v-btn v-bind="attrs" v-on="on" class="my-2" color="error" :loading="loadingApproval" :disabled="loadingApproval || disableReproveAll || selectAllToApprove" block>
                                                    Reprovar {{ selected.length > 0 ? selected.length : 'todos' }} contrato(s)
                                                </v-btn>
                                            </v-card>
                                        </template>

                                        <v-list>
                                            <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn @click="approveAll(0)" class="my-2" color="primary" block>
                                                        Sim
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn class="my-2" color="primary" block text>
                                                        Não
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-checkbox
                                        v-model="selectAllToReprove"
                                        :disabled="selectAllToApprove"
                                        :label="'Selecionar todos liberados para reprovar'"
                                        class="mx-2"
                                    />
                                </v-flex>
                                <v-flex xs12 sm12 md6>
                                    <v-menu
                                        top
                                        close-on-click
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-card class="ml-1" elevation="0" color="transparent">
                                                <v-btn v-bind="attrs" v-on="on" class="my-2" color="success" :loading="loadingApproval" :disabled="loadingApproval || disableApproveAll || selectAllToReprove" block>
                                                    Aprovar {{ selected.length > 0 ? selected.length : 'todos' }} contrato(s)
                                                </v-btn>
                                            </v-card>
                                        </template>

                                        <v-list>
                                            <span class="font-weight-bold mx-4">Deseja prosseguir?</span>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn @click="approveAll(1)" class="my-2" color="primary" block>
                                                        Sim
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-layout justify-center>
                                                    <v-btn class="my-2" color="primary" block text>
                                                        Não
                                                    </v-btn>
                                                </v-layout>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <v-checkbox
                                        v-model="selectAllToApprove"
                                        :disabled="selectAllToReprove"
                                        :label="'Selecionar todos liberados para aprovar'"
                                        class="mx-2"
                                    />
                                </v-flex>
                                <v-flex xs12>
                                    <v-data-table
                                        v-model="selected"
                                        show-select
                                        :headers="headersApprovalAll"
                                        :items="allActualLevels"
                                        :loading="loading"
                                        item-key="id"
                                        class="pb-2"
                                        ref="dTable"
                                    >
                                        <template v-slot:[`header.data-table-select`]></template>
                                        <!-- eslint-disable-next-line -->
                                        <template v-slot:item.actualLevel="{ item }">
                                            <v-layout v-if="item.actualLevel">
                                                <v-flex class=" mt-5">
                                                    {{ item.actualLevel.approval_flow_level_description }}
                                                </v-flex>
                                                <v-spacer></v-spacer>
                                                <v-flex>
                                                    <v-text-field
                                                        v-model="item.justification"
                                                        class="mx-2 my-n1"
                                                        label="Justificativa"
                                                    ></v-text-field>
                                                </v-flex>
                                                <v-btn 
                                                    @click="selectedPlanningGroup = item.id_planning_group, selectedProduct = item.id_product, justification = item.justification, approvalSelect(0)"
                                                    fab
                                                    depressed
                                                    x-small
                                                    class="error mr-2 mt-3"
                                                    :loading="loadingApproval"
                                                    :disabled="loadingApproval || !item.actualLevel.enable || item.actualLevel.id_level === 1 && item.actualLevel.id_status !== 4"
                                                >
                                                    <v-icon small>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    @click="selectedPlanningGroup = item.id_planning_group, selectedProduct = item.id_product, justification = item.justification, approvalSelect(1)"
                                                    fab
                                                    depressed
                                                    x-small 
                                                    class="success mr-2 mt-3"
                                                    :loading="loadingApproval"
                                                    :disabled="loadingApproval || !item.actualLevel.enable || item.actualLevel.id_status === 4"
                                                >
                                                    <v-icon small>
                                                        mdi-check
                                                    </v-icon>
                                                </v-btn>
                                            </v-layout>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card>
                </v-dialog>

                <v-menu
                    bottom
                    offset-y
                    style="z-index: 1000 !important;"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" class="mr-4 pa-0"
                            v-on="on"
                            min-width="48px"
                            :disabled="disableActionButtons"
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </template>

                    <v-list style="overflow-y: auto;">
                        <v-list-item @click="showFormulas()">
                            <v-list-item-content>
                                <v-list-item-title>{{ isShowingFormulas ? 'Ocultar fórmulas' : 'Exibir fórmulas' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="showStyles()">
                            <v-list-item-content>
                                <v-list-item-title>{{ isShowingStyles ? 'Remover formatação' : 'Exibir formatação' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <template>
                    <v-dialog v-model="dialogCloseGrid" max-width="850px">
                        <template v-slot:activator="{ on: dialog }" >
                            <v-tooltip top>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn color="primary" class="pa-0"
                                        v-on="{ ...tooltip, ...dialog}"
                                        min-width="48px"
                                        @click="closeGrid()"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </template>
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseGrid = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="secondary" text @click="editedRows = [], closeGrid()" class="mr-4">Continuar sem salvar</v-btn>
                                <v-btn color="primary" @click="closeGrid(save = true)" :loading="saving">Salvar e continuar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </div>

            <v-divider class="mb-1"></v-divider>

            <v-row no-gutters class="d-flex justify-start align-center mb-1" style="height: 24px !important; width: 100%;">
                <v-col class="d-flex justify-start" style="height: 24px !important;">
                    <v-tabs  
                        v-model="tab"
                        height="24px"
                    >
                        <v-tabs-slider :color="'primary'"></v-tabs-slider>

                        <v-tab
                            v-for="item in tabItems"
                            :key="item"
                        >
                            <span class="text-capitalize">{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                </v-col>

                <v-spacer></v-spacer>

                <v-col v-if="readOnly || isShowingFormulas"
                    class="d-flex justify-end"
                    style="height: 24px !important; cursor: pointer !important;"
                >
                    <v-menu offset-y
                        style="z-index: 1000 !important;"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on"
                                class="d-flex justify-end align-start"
                            >
                                <div class="d-flex align-center">
                                    <v-icon small color="secondary" class="mr-2">mdi-alert-decagram</v-icon>
                                    <span class="secondary--text text-caption">Somente consulta</span>
                                </div>
                            </div>
                        </template>
                        <v-card>
                            <v-card-text>
                                <v-list-item dense
                                    v-for="(item, i) in readOnlyRules" :key="i"
                                    class="ma-0 pa-0"
                                >
                                    <v-list-item-icon class="mx-0 mr-2 pa-0">
                                        <v-icon color="secondary">mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-caption font-weight-regular">{{ item }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-row align="center" no-gutters class="pt-3">

                        <!-- EVENTO -->
                        <v-autocomplete
                            name="event"
                            :label="eventOption.label"
                            :items="eventOption.items"
                            item-value="text"
                            return-object
                            class="pa-0 mr-4"
                            v-model="event"
                            @change="selectEvent()"
                            @click="backupEvent = event"
                            :loading="loadingEvent"
                            style="max-width: 300px !important; z-index: 1000 !important;"
                            :disabled="unsavedChanges"
                        />

                        <v-dialog v-model="dialogEvent" max-width="850px">
                            <v-card>
                                <div class="d-flex justify-center">
                                    <v-card-title class="headline">Você possui edições não salvas. Tem certeza de que deseja continuar?</v-card-title>
                                </div>
                                <v-card-actions class="px-6 pb-6">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="closeDialogEvent()" class="mr-4">Cancelar</v-btn>
                                    <v-btn color="secondary" text @click="editedRows = [], selectEvent()" class="mr-4">Continuar sem salvar</v-btn>
                                    <v-btn color="primary" @click="selectEvent(save = true)" :loading="saving">Salvar e continuar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <!-- GRUPO DE PLANEJAMENTO -->
                        <div @click="setBackupPlanningGroup()">
                            <v-autocomplete
                                v-model="planningGroup"
                                :items="loadingPlanningGroup ? [] : planningGroupOption.items"
                                :label="planningGroupOption.label"
                                multiple
                                :loading="loadingPlanningGroup"
                                class="pa-0 mr-4"
                                style="max-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @blur="confirmPlanningGroupSelection()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingPlanningGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingPlanningGroup">
                                    <template v-if="loadingPlanningGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="planningGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllPlanningGroups()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!planningGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllPlanningGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="planningGroup.length === planningGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="planningGroup.length !== planningGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${planningGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <div class="d-flex justify-start">
                            <span class="text-h6 mr-4"> {{ year1 ? year1.toString() : 'Ano 1' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1month"
                                    @click="showAllMonths(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="260px">
                                        <v-select
                                            v-model="year1months"
                                            :items="months"
                                            label="Escolha os meses"
                                            :item-text="item => item.text"
                                            @change="showMonth(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1month = true, showAllMonths(1)"
                                                :disabled="year1months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Tri"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1quarter"
                                    @click="showAllQuarters(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year1quarters"
                                            :items="quarters"
                                            label="Escolha os trimestres"
                                            :item-text="item => item.text"
                                            @change="showQuarter(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1quarters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1quarter = true, showAllQuarters(1)"
                                                :disabled="year1quarters.length === 4"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Sem"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year1semester"
                                    @click="showAllSemesters(1)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year1semesters"
                                            :items="semesters"
                                            label="Escolha os semestres"
                                            :item-text="item => item.text"
                                            @change="showSemester(1)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year1semesters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year1semester = true, showAllSemesters(1)"
                                                :disabled="year1semesters.length === 2"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Ano 1"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="startingYear"
                                    @click="showYear(1)"
                                    :disabled="disableActionButtons"
                                />
                            </div>
                        </div>

                        <v-divider vertical class="mr-4 mb-4"></v-divider>

                        <div class="d-flex justify-start">
                            <span class="text-h6 mr-4"> {{ year2 ? year2.toString() : 'Ano 2' }} </span>
                            <div class="d-flex mt-1">
                                <v-checkbox
                                    label="Mês"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2month"
                                    @click="showAllMonths(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2months"
                                            :items="months"
                                            :item-text="item => item.text"
                                            @change="showMonth(2)"
                                            label="Escolha os meses"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2months.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2month = true, showAllMonths(2)"
                                                :disabled="year2months.length === 12"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Tri"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2quarter"
                                    @click="showAllQuarters(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2quarters"
                                            :items="quarters"
                                            label="Escolha os trimestres"
                                            :item-text="item => item.text"
                                            @change="showQuarter(2)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2quarters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2quarter = true, showAllQuarters(2)"
                                                :disabled="year2quarters.length === 4"

                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Sem"
                                    :ripple="false"
                                    class="pa-0 ma-0"
                                    v-model="year2semester"
                                    @click="showAllSemesters(2)"
                                    :disabled="disableActionButtons"
                                />

                                <v-menu
                                    bottom
                                    left
                                    :close-on-content-click="false"
                                    style="z-index: 1000 !important;"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            x-small
                                            class="mr-4 pt-1"
                                            :disabled="disableActionButtons"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-sheet class="px-4 py-2" max-width="240px">
                                        <v-select
                                            v-model="year2semesters"
                                            :items="semesters"
                                            label="Escolha os semestres"
                                            :item-text="item => item.text"
                                            @change="showSemester(2)"
                                            multiple
                                            return-object
                                        >
                                            <template v-slot:selection="{ item, index }">
                                                <span v-if="index === 0">{{ item.text }}</span>

                                                <span v-if="index === 1">{{ `, ${item.text}` }}</span>

                                                <template v-if="index === 2">
                                                    <span>...</span>
                                                    <span class="grey--text text-caption pa-1">
                                                        {{ `+ ${year2semesters.length - 2}` }}
                                                    </span>
                                                </template>

                                            </template>
                                        </v-select>

                                        <div class="d-flex justify-end">
                                            <v-btn small color="primary" class="ma-0 mb-2"
                                                @click="year2semester = true, showAllSemesters(2)"
                                                :disabled="year2semesters.length === 2"
                                            >
                                                Selecionar todos
                                            </v-btn>
                                        </div>
                                    </v-sheet>
                                </v-menu>

                                <v-checkbox
                                    label="Ano 2"
                                    :ripple="false"
                                    class="pa-0 ma-0 mr-4"
                                    v-model="laterYear"
                                    @click="showYear(2)"
                                    :disabled="disableActionButtons"
                                />
                            </div>
                        </div>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="pt-3">

                        <!-- FORNECEDOR -->
                        <v-col>
                            <v-autocomplete
                                v-model="vendor"
                                :items="loadingVendor ? [] : vendorOption.items"
                                :label="vendorOption.label"
                                multiple
                                :loading="loadingVendor"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getVendors()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingVendor ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingVendor">
                                    <template v-if="loadingVendor">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="vendorOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllVendors()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!vendor.length ? 'gray' : 'primary'">
                                                    {{ selectAllVendorIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="vendor.length === vendorOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="vendor.length !== vendorOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${vendor.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTRATO -->
                        <v-col>
                            <v-autocomplete
                                v-model="product"
                                :items="loadingProduct ? [] : productOption.items"
                                :label="productOption.label"
                                multiple
                                :loading="loadingProduct"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                :disabled="unsavedChanges"
                                :no-data-text="loadingProduct ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingProduct">
                                    <template v-if="loadingProduct">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="productOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllProducts()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!product.length ? 'gray' : 'primary'">
                                                    {{ selectAllProductIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="product.length === productOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="product.length !== productOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${product.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- COLABORADOR -->
                        <v-col>
                            <v-autocomplete
                                v-model="employee"
                                :items="loadingEmployee ? [] : employeeOption.items"
                                :label="employeeOption.label"
                                multiple
                                :loading="loadingEmployee"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getEmployees()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingEmployee ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingEmployee">
                                    <template v-if="loadingEmployee">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="employeeOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllEmployees()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!employee.length ? 'gray' : 'primary'">
                                                    {{ selectAllEmployeeIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="employee.length === employeeOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="employee.length !== employeeOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${employee.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CENTRO DE CUSTO -->
                        <v-col>
                            <v-autocomplete
                                v-model="costCenter"
                                :items="loadingCostCenter ? [] : costCenterOption.items"
                                :label="costCenterOption.label"
                                multiple
                                :loading="loadingCostCenter"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                :disabled="unsavedChanges"
                                :no-data-text="loadingCostCenter ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingCostCenter">
                                    <template v-if="loadingCostCenter">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="costCenterOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCostCenters()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!costCenter.length ? 'gray' : 'primary'">
                                                    {{ selectAllCostCenterIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="costCenter.length === costCenterOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="costCenter.length !== costCenterOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${costCenter.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTA GERENCIAL -->
                        <v-col>
                            <v-autocomplete
                                v-model="accountGroup"
                                :items="loadingAccountGroup ? [] : accountGroupOption.items"
                                :label="accountGroupOption.label"
                                multiple
                                :loading="loadingAccountGroup"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getAccountGroups()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingAccountGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingAccountGroup">
                                    <template v-if="loadingAccountGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="accountGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCostCenters()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!accountGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllAccountGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="accountGroup.length === accountGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="accountGroup.length !== accountGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${accountGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTA CONTÁBIL -->
                        <v-col>
                            <v-autocomplete
                                v-model="chartAccount"
                                :items="loadingChartAccount ? [] : chartAccountOption.items"
                                :label="chartAccountOption.label"
                                multiple
                                :loading="loadingChartAccount"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getChartAccounts()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingChartAccount ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingChartAccount">
                                    <template v-if="loadingChartAccount">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="chartAccountOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCostCenters()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!chartAccount.length ? 'gray' : 'primary'">
                                                    {{ selectAllChartAccountIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="chartAccount.length === chartAccountOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="chartAccount.length !== chartAccountOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${chartAccount.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- TIPO DE CONTRATO -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesChannel"
                                :items="loadingSalesChannel ? [] : salesChannelOption.items"
                                :label="salesChannelOption.label"
                                multiple
                                :loading="loadingSalesChannel"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesChannels()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingSalesChannel ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesChannel">
                                    <template v-if="loadingSalesChannel">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesChannelOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesChannels()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesChannel.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesChannelIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesChannel.length === salesChannelOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesChannel.length !== salesChannelOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesChannel.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- GERÊNCIA EXECUTIVA -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesForce"
                                :items="loadingSalesForce ? [] : salesForceOption.items"
                                :label="salesForceOption.label"
                                multiple
                                :loading="loadingSalesForce"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesForces()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingSalesForce ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesForce">
                                    <template v-if="loadingSalesForce">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesForceOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesForces()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesForce.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesForceIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesForce.length === salesForceOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesForce.length !== salesForceOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesForce.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- BP -->
                        <v-col>
                            <v-autocomplete
                                v-model="salesForceBP"
                                :items="loadingSalesForceBP ? [] : salesForceBPOption.items"
                                :label="salesForceBPOption.label"
                                multiple
                                :loading="loadingSalesForceBP"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getSalesForceBPs()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingSalesForceBP ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingSalesForceBP">
                                    <template v-if="loadingSalesForceBP">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="salesForceBPOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllSalesForceBPs()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!salesForceBP.length ? 'gray' : 'primary'">
                                                    {{ selectAllSalesForceBPIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="salesForceBP.length === salesForceBPOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="salesForceBP.length !== salesForceBPOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${salesForceBP.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- CONTA CLIENTE -->
                        <v-col>
                            <v-autocomplete
                                v-model="customerGroup"
                                :items="loadingCustomerGroup ? [] : customerGroupOption.items"
                                :label="customerGroupOption.label"
                                multiple
                                :loading="loadingCustomerGroup"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getCustomerGroups()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingCustomerGroup ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingCustomerGroup">
                                    <template v-if="loadingCustomerGroup">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="customerGroupOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllCustomerGroups()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!customerGroup.length ? 'gray' : 'primary'">
                                                    {{ selectAllCustomerGroupIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="customerGroup.length === customerGroupOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="customerGroup.length !== customerGroupOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${customerGroup.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- DESCRIÇÃO DO ITEM -->
                        <v-col>
                            <v-autocomplete
                                v-model="purchaseItemCode"
                                :items="loadingPurchaseItemCode ? [] : purchaseItemCodeOption.items"
                                :label="purchaseItemCodeOption.label"
                                multiple
                                :loading="loadingPurchaseItemCode"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getPurchaseItemCodes()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingPurchaseItemCode ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingPurchaseItemCode">
                                    <template v-if="loadingPurchaseItemCode">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="purchaseItemCodeOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllPurchaseItemCodes()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!purchaseItemCode.length ? 'gray' : 'primary'">
                                                    {{ selectAllPurchaseItemCodeIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="purchaseItemCode.length === purchaseItemCodeOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="purchaseItemCode.length !== purchaseItemCodeOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${purchaseItemCode.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>

                        <!-- STATUS -->
                        <v-col>
                            <v-autocomplete
                                v-model="status"
                                :items="loadingStatus ? [] : statusOption.items"
                                :label="statusOption.label"
                                multiple
                                :loading="loadingStatus"
                                class="pa-0 mr-4"
                                style="min-width: 300px !important; z-index: 1000 !important;"
                                return-object
                                @click="getStatus()"
                                :disabled="unsavedChanges"
                                :no-data-text="loadingStatus ? 'Atualizando dados...' : 'Não há dados disponíveis'"
                            >
                                <template v-slot:prepend-item v-if="!loadingStatus">
                                    <template v-if="loadingStatus">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    Atualizando dados...
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>

                                    <template v-else-if="statusOption.items.length">
                                        <v-list-item
                                            :ripple="false"
                                            @mousedown.prevent
                                            @click="setAllStatus()"
                                        >
                                            <v-list-item-action>
                                                <v-icon :color="!status.length ? 'gray' : 'primary'">
                                                    {{ selectAllStatusIcon }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    --- TODOS ---
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="status.length === statusOption.items.length && index === 0">--- TODOS ---</span>

                                    <span v-else-if="status.length !== statusOption.items.length"
                                        style="display: inline-block; max-width: 250px !important; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                    >
                                        <span v-if="index === 0" class="mr-1">
                                            {{ item.text }}
                                        </span>

                                        <span v-if="index === 1" class="text-caption primary--text mr-1"
                                            style="max-width: 20px !important;"
                                        >
                                            {{ `+ ${status.length - 1}` }}
                                        </span>
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <v-row no-gutters class="d-flex justify-start align-center pt-2 pb-3">
                        <v-col class="d-flex justify-start" cols="2"
                            @click="handleSetAllHiddenColumns()"
                            style="cursor: pointer"
                        >
                            <v-icon class="mr-2" :color="setAllHiddenColumnsColor">{{ setAllHiddenColumnsIcon }}</v-icon>
                            <span class="text-subtitle-2">{{ setAllHiddenColumnsText }}</span>
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Canal de compras"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPurchaseChannel"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesChannelOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesChannel"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesForceOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesForce"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="salesForceBPOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showSalesForceBP"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="customerGroupOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCustomerGroup"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="costCenterOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCostCenter"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="vendorOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showVendor"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="employeeOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showEmployee"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <!-- <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Percentual do contrato"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showContractPercentage"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col> -->

                        <!-- <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Preço do contrato"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showContractPrice"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Quantidade contratada"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showContractQuantity"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col> -->

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="productOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showProduct"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="purchaseItemCodeOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPurchaseItemCode"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Mês de renovação"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showRenewalMonth"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Índice de reajuste"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showMarketIndex"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Início do contrato"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showStartDate"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Término do contrato"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showEndDate"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Condição de pagamento"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPaymentCondition"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="statusOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showStatus"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Moeda"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCurrency"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>
                        
                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                :label="chartAccountOption.label"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showChartAccount"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="MEMO"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showPurchaseDescription"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>

                        <v-col class="d-flex justify-start" cols="2">
                            <v-checkbox
                                label="Código do ERP"
                                :ripple="false"
                                class="pa-0 ma-0"
                                v-model="showCdIdErp"
                                style="display: inline-block; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-sheet>

        <v-sheet v-if="loading" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex justify-center align-center" style="width: 100%; height: 100%;">
                <v-btn large text :loading="loading" color="primary">
                    Carregando
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else-if="!loading && noDataMessage" :height="windowSize.y - 64 - 192" class="rounded-b">
            <div class="d-flex flex-column justify-center align-center" style="width: 100%; height: 100%;">
                <span>{{ noDataMessage }}</span>
                <v-btn v-if="noDataBtn.text"
                    large text color="primary" class="mt-4"
                    @click="$router.push(noDataBtn.path)"
                >
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    {{ noDataBtn.text }}
                </v-btn>
            </div>
        </v-sheet>

        <v-sheet v-else :height="windowSize.y - 64 - 192" class="rounded-b px-4">
            <HotTable ref="hotTable"
                licenseKey="1c81c-153e1-cb283-a4838-af03f"
                :data="objectData"
                :settings="settings"
                :columns="columns"
                :colHeaders="setColHeaders()"
                :rowHeaders="true"
                :hiddenColumns="hiddenColumns"
                :hiddenRows="hiddenRows"
                :contextMenu="contextMenu"
                :dropdownMenu="dropDownMenu"
                :manualColumnResize="true"
                :multiColumnSorting="true"
                :bindRowsWithHeaders="true"
                :filters="true"
                fixedColumnsLeft="0"
                width="100%"
                height="100%"
                :columnHeaderHeight="50"
                :rowHeaderWidth="50"
                :trimWhitespace="false"
                :copyPaste="true"
                :renderAllRows="false"
            />
        </v-sheet>

        <CommentsMenu v-if="drawer"
            :drawer="drawer"
            @closeDrawer="drawer = false"
            :parentPayload="{
                id_event: event ? event.id : null,
                id_planning_group: planningGroup.length ? planningGroup[0].id : null,
            }"
        />

        <ZnapStylesDialog v-if="dialogStyles"
            :dialogStyles="dialogStyles"
            :editedRowStyles="editedRowStyles"
            @closeDialogStyles="dialogStyles = false"
            @saveEditedRowStyle="saveEditedRowStyle($event)"
        />

        <v-dialog v-model="dialogSolver" max-width="900px"
            style="z-index: 5000 !important;"
        >
            <v-card>
                <v-card-title class="headline neutral">
                    <span class="headline">Atingir meta</span>
                </v-card-title>
                <v-card-text>
                    <ZnapSolverDialog 
                        :solver="solverProps"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <ZnapPurchasePlanningItemDialog v-if="itemDialog"
            :itemDialog="itemDialog"
            :event="event"
            :selectedPlanningGroup="selectedPlanningGroup"
            :selectedRow="selectedRow"
            :duplicatingItem="duplicatingItem"
            @closeItemDialog="closeItemDialog()"
            @setRefresh="loadGrid()"
        />
    </v-layout>
</template>

<script>
import lodash from 'lodash'
import { mapGetters } from 'vuex'
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import { HyperFormula } from 'hyperformula'
import moment from 'moment'
import CommentsMenu from '@/components/znap/CommentsMenu.vue'
import ZnapSolverDialog from '@/components/znap/ZnapSolverDialog.vue'
import ZnapStylesDialog from '@/components/znap/ZnapStylesDialog.vue'
import ZnapPurchasePlanningItemDialog from '@/components/znap/ZnapPurchasePlanningItemDialog.vue'

export default {
    name: 'Handsontable',

    components: {
        HotTable, CommentsMenu, ZnapSolverDialog, ZnapStylesDialog, ZnapPurchasePlanningItemDialog
    },

    props: {
        options: { type: Array, required: true },
    },

    data() {
        return {
            itemDialog: false,
            
            tab: 0,
            tabItems: ['Evento', 'Filtros', 'Exibir'],

            objectData: [],
            settings: {
                formulas: {
                    engine: HyperFormula,
                },
                afterSelection: () => {
                    if (this.hotInstance) {
                        let cellCoords = this.hotInstance.getSelectedLast()

                        if (cellCoords) {
                            let firstRow = this.hotInstance.toPhysicalRow(cellCoords[0])
                            let lastRow = this.hotInstance.toPhysicalRow(cellCoords[2])

                            if (firstRow === lastRow) {
                                this.selectedRowId = firstRow

                                // não foi feito rateio, não tem centro de custo nem percentual preenchido
                                this.selectedRow = this.objectData[firstRow]
                                let { id_cost_center } = this.selectedRow
                                if (id_cost_center) {
                                    this.editRatio = true
                                } else {
                                    this.editRatio = false
                                }
                            } else {
                                this.selectedRowId = [ firstRow, lastRow ]
                            }
                        } else {
                            this.selectedRowId = null
                        }
                    }
                },
                afterChange: (changes) => {
                    if (changes) {
                        let editedChanges = changes.filter(c => c[3] != c[2])
                        editedChanges.forEach(([ row, tableColumn, oldVal, newVal ]) => {
                            let physicalRow = this.hotInstance.toPhysicalRow(row)

                            if (oldVal === newVal) {
                                return
                            }

                            // let optionColumn = tableColumn
                            // let option = this.dropdownOptions.find(d => d.column === optionColumn)
                            // if (option) {
                                // if (tableColumn === 'vendor_description') {
                                //     if (!newVal) {
                                //         this.objectData[physicalRow]['id_vendor'] = null
                                //         this.objectData[physicalRow]['cnpj'] = null
                                //     } else {
                                //         let foundItem = option.items.find(i => i.text === newVal)
                                //         this.objectData[physicalRow]['id_vendor'] = foundItem.id
                                //         this.objectData[physicalRow]['cnpj'] = foundItem.cnpj
                                //     }

                                //     this.hotInstance.render()
                                // }
                            // }

                            if (newVal === '') {
                                newVal = null
                                this.objectData[physicalRow][tableColumn] = newVal
                            }
                            
                            if (!this.editedRows.includes(row)) {
                                this.editedRows.push(row)
                            }
                        })
                    }
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData);
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
                cells: (row, col, prop) => {
                    var cellProperties = {}

                    const stylizedRow = this.stylizedRows[row] || { index: -1 }
                    const accountGroupRowIndex = Object.keys(this.objectData[0]).findIndex(k => k === 'account_group_description')

                    if (row === stylizedRow.index) {
                        if (this.isShowingStyles) {
                            if (stylizedRow.className) {
                                if (stylizedRow.className.includes('first-column-only')) {
                                    if (col === accountGroupRowIndex) {
                                        cellProperties.className = stylizedRow.className
                                    } else {
                                        let arrayOfStyles = stylizedRow.className.split(' ')
                                        let borderStyles = arrayOfStyles.filter(s => s.includes('border')).join(' ')
                                        cellProperties.className = borderStyles
                                    }
                                } else {
                                    cellProperties.className = stylizedRow.className
                                }
                            }
                        }

                        if (stylizedRow.readOnly) {
                            cellProperties.readOnly = stylizedRow.readOnly
                        }

                        if (this.readOnlyColumns.includes(col)) {
                            const totalColumns = [
                                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                            ]

                            if (totalColumns.includes(prop)) {
                                cellProperties.className += ` border-sides-1 font-bold`
                            } else {
                                cellProperties.className += ` read-only-column`
                            }
                        }

                        if (stylizedRow.percent) {
                            cellProperties.numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    if (this.isShowingFormulas) {
                        cellProperties.readOnly = false
                    } else {
                        if (this.readOnly) {
                            cellProperties.readOnly = true
                        }
                    }

                    return cellProperties
                },
            },
            columns: [],
            colHeaders: [],
            rowHeaders: [],
            stylizedRows: [],
            hiddenColumns: {},
            hiddenRows: { rows: [], indicators: true },
            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
                {
                    name() {
                        return 'Estilizar linha'
                    },
                    hidden() {
                        let hidden = true
                        const cellCoords = this.getSelectedLast()
                        if (cellCoords[1] === -1) {
                            hidden = false
                        }
                        return hidden
                    },
                    callback: () => {
                        this.dialogStyles = true
                        let row = this.hotInstance.getSelectedLast()[2]
                        let id = this.objectData[row].id_account_group
                        let css = this.objectData[row].css

                        this.editedRowStyles = { id, css, row }
                    }
                },
                {
                    name() {
                        return 'Editar'
                    },
                    hidden() {
                        let hidden = false
                        const cellCoords = this.getSelectedLast()
                        const firstRow = cellCoords[0]
                        const lastRow = cellCoords[2]
                        if (firstRow < 1 || firstRow !== lastRow) {
                            hidden = true
                        }
                        return hidden
                    },
                    callback: (key, selection) => {
                        this.selectedRowId = selection[0].start.row
                        // this.newEmployeeAllocationDialog = true
                    }
                }
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            dialogSolver: false,
            solverProps: {
                columnName: null,
                column: null,
                row: null,
                rowProps: null,
                targetRow: null,
                targetValue: null,
                changeRow: null,
            },

            payload: {
                id_event: null,
                id_planning_group: null,
                id_purchase_channel: null,
                id_vendor: null,
                id_product: null,
                id_employee: null,
                purchase_item_code: null,
                id_account_group_version: null,
            },

            // ! DADOS DO LOAD
            event: null,
            loadingEvent: true,
            dialogEvent: false,
            backupEvent: null,

            accountGroupVersion: null,

            planningGroup: [],
            loadingPlanningGroup: true,
            backupPlanningGroup: null,

            showPurchaseChannel: false,

            salesChannel: [],
            loadingSalesChannel: false,
            showSalesChannel: false,

            salesForce: [],
            loadingSalesForce: false,
            showSalesForce: false,

            salesForceBP: [],
            loadingSalesForceBP: false,
            showSalesForceBP: false,

            customerGroup: [],
            loadingCustomerGroup: false,
            showCustomerGroup: false,

            costCenter: [],
            loadingCostCenter: false,
            showCostCenter: false,

            accountGroup: [],
            loadingAccountGroup: false,
            showAccountGroup: false,

            chartAccount: [],
            loadingChartAccount: false,
            showChartAccount: false,

            vendor: [],
            loadingVendor: false,
            showVendor: true,

            product: [],
            loadingProduct: true,
            showProduct: true,

            employee: [],
            loadingEmployee: false,
            showEmployee: true,

            purchaseItemCode: [],
            loadingPurchaseItemCode: false,
            showPurchaseItemCode: false,

            showPaymentCondition: false,

            status: [],
            loadingStatus: false,
            showStatus: true,

            showCurrency: false,
            showMarketIndex: false,
            showPurchaseDescription: true,
            showProductSomething: true,
            showRenewalMonth: true,
            showStartDate: true,
            showEndDate: true,
            showContractValue: true,
            showContractQuantity: true,
            showContractPercentage: true,
            showContractPrice: true,
            showCdIdErp: true,

            selectedRowId: null,
            selectedRow: {},
            duplicatingItem: false,

            dialogCloseGrid: false,
            dialogRenewGrid: false,
            dialogRefreshGrid: false,
            dialogStyles: false,
            dialogMassDelete: false,
            deleting: false,

            disableEdition: false,
            readOnlyRules: [],

            dialogRatio: false,
            editRatio: false,
            ratioCostCenterItems: [],
            selectedRatioCostCenterItems: [],
            searchRatio: '',
            searchSelectedRatio: '',

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            year1: null,
            year2: null,
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            readOnlyColumns: [],
            isShowingFormulas: false,
            isShowingStyles: true,
            backupColHeaders: [],
            backupReadOnly: null,

            // Unsaved changes
            editedRows: [],

            windowSize: { x: 0, y: 0 },
            loading: true,
            saving: false,
            noDataMessage: null,
            noDataBtn: { path: null, text: null },

            hotInstance: null,

            drawer: false,
            exporting: false,
            importing: false,

            updateMonthsDialog: false,
            loadingUpdateMonths: false,

            // workflow
            readOnly: false,
            readOnlyApproval: false,
            dialogApproval: false,
            dialogApprovalAll: false,
            selected: [],
            doRefresh: false,
            loadingApproval: false,
            justification: '',
            headers: [
                {
                    text: 'Semáforo', value: 'id_status'
                },
                {
                    text: 'Nível', value: 'approval_flow_level_description'
                },
                {
                    text: 'Status', value: 'status'
                },
                {
                    text: 'Usuário', value: 'name'
                },
                {
                    text: 'Data', value: 'date'
                },
                {
                    text: 'Justificativa', value: 'justification'
                }
            ],
            headersApprovalAll: [
                {
                    text: 'ID', value: 'id_product'
                },
                {
                    text: 'Contrato', value: 'text'
                },
                {
                    text: 'Nível', value: 'actualLevel'
                }
            ],
            levels: [],
            allActualLevels: [],
            planningGroups: [],
            selectedPlanningGroup: null,
            selectedProduct: null,
            selectedEvent: null,
            showHistory: false,
            approvals: null,
            userGroups: [],
            actualLevel: {
                id_level: 0,
                id_group: 0
            },
            disableApprove: true,
            selectAllToApprove: false,
            selectAllToReprove: false,
        }
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
        this.addPreventCloseHandler()
    },

    updated() {
        if (this.$refs.hotTable) {
            if (this.hotInstance === null) {
                this.hotInstance = this.$refs.hotTable.hotInstance
                this.hotInstance.render()
            }
        }
    },

    destroyed() {
        this.removePreventCloseHandler()
    },

    computed: {
        ...mapGetters('auth', ['getUser', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName
            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon
            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        disableActionButtons() {
            return this.loadingEvent
                || this.loadingPlanningGroup
        },

        unsavedChanges() {
            return this.editedRows.length > 0;
        },

        dropdownOptions() {
            return this.options.filter(o => o.isDropdownOption)
        },

        hasMultipleSelection() {
            return this.planningGroup.length > 1
                || this.vendor.length > 1
                || this.product.length > 1
                || this.employee.length > 1
        },

        hasMultipleColumns() {
            return this.showPurchaseChannel
                || this.showSalesChannel
                || this.showSalesForce
                || this.showSalesForceBP
                || this.showCustomerGroup
                || this.showCostCenter
                || this.showAccountGroup
                || this.showChartAccount
                || this.showVendor
                || this.showPurchaseDescription
                || this.showProduct
                || this.showEmployee
                || this.showPurchaseItemCode
                || this.showMarketIndex
                || this.showRenewalMonth
                || this.showStartDate
                || this.showEndDate
                || this.showContractPercentage
                || this.showCdIdErp
                // || this.showContractQuantity
                // || this.showContractPrice
        },

        hasEmptyRequiredFilter() {
            return !this.event
        },

        disableLoadGrid() {
            return false
        },

        setAllHiddenColumnsText() {
            return this.hasMultipleColumns ? 'DESMARCAR TODOS' : 'SELECIONAR TODOS'
        },

        setAllHiddenColumnsIcon() {
            if (!this.hasMultipleColumns) {
                return 'mdi-checkbox-blank-outline'
            } else {
                return 'mdi-close-box'
            }
        },

        setAllHiddenColumnsColor() {
            return this.hasMultipleColumns ? 'primary' : ''
        },

        eventOption() {
            return this.options.find(o => o.column === 'id_event')
        },

        planningGroupOption() {
            return this.options.find(o => o.column === 'id_planning_group')
        },

        salesChannelOption() {
            return this.options.find(o => o.column === 'id_sales_channel')
        },

        salesForceOption() {
            return this.options.find(o => o.column === 'id_sales_force')
        },

        salesForceBPOption() {
            return this.options.find(o => o.column === 'id_sales_force_bp')
        },

        customerGroupOption() {
            return this.options.find(o => o.column === 'id_customer_group')
        },

        costCenterOption() {
            return this.options.find(o => o.column === 'id_cost_center')
        },

        accountGroupOption() {
            return this.options.find(o => o.column === 'id_account_group')
        },

        chartAccountOption() {
            return this.options.find(o => o.column === 'id_chart_account')
        },

        vendorOption() {
            return this.options.find(o => o.column === 'id_vendor')
        },

        productOption() {
            return this.options.find(o => o.column === 'id_product')
        },

        employeeOption() {
            return this.options.find(o => o.column === 'id_employee')
        },

        purchaseItemCodeOption() {
            return this.options.find(o => o.column === 'purchase_item_code')
        },

        statusOption() {
            return this.options.find(o => o.column === 'id_status')
        },

        selectAllPlanningGroupIcon() {
            let icon = ''

            if (!this.planningGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.planningGroup.length < this.planningGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesChannelIcon() {
            let icon = ''

            if (!this.salesChannel.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesChannel.length < this.salesChannelOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesForceIcon() {
            let icon = ''

            if (!this.salesForce.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesForce.length < this.salesForceOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllSalesForceBPIcon() {
            let icon = ''

            if (!this.salesForceBP.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.salesForceBP.length < this.salesForceBPOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllCustomerGroupIcon() {
            let icon = ''

            if (!this.customerGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.customerGroup.length < this.customerGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllCostCenterIcon() {
            let icon = ''

            if (!this.costCenter.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.costCenter.length < this.costCenterOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllVendorIcon() {
            let icon = ''

            if (!this.vendor.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.vendor.length < this.vendorOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllProductIcon() {
            let icon = ''

            if (!this.product.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.product.length < this.productOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllEmployeeIcon() {
            let icon = ''

            if (!this.employee.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.employee.length < this.employeeOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllPurchaseItemCodeIcon() {
            let icon = ''

            if (!this.purchaseItemCode.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.purchaseItemCode.length < this.purchaseItemCodeOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllStatusIcon() {
            let icon = ''

            if (!this.status.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.status.length < this.statusOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        selectAllAccountGroupIcon() {
            let icon = ''

            if (!this.accountGroup.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.accountGroup.length < this.accountGroupOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },

        disableApproveAll () {
            let disable = []

            if (this.selected.length > 0) {
                if (this.selected.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.selected.find(level => level.actualLevel.id_status === 4)) disable.push(true)
            } else {
                if (this.allActualLevels.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.allActualLevels.find(level => level.actualLevel.id_status === 4)) disable.push(true)
            }

            if (this.allActualLevels.length < 1) disable.push(true)

            return disable.includes(true)
        },
        disableReproveAll () {
            let disable = []

            if (this.selected.length > 0) {
                if (this.selected.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.selected.find(level => level.actualLevel.id_level === 1 && level.actualLevel.id_status !== 4)) disable.push(true)
            } else {
                if (this.allActualLevels.find(level => !level.actualLevel.enable)) disable.push(true)
                if (this.allActualLevels.find(level => level.actualLevel.id_level === 1 && level.actualLevel.id_status !== 4)) disable.push(true)
            }
            if (this.allActualLevels.length < 1) disable.push(true)

            return disable.includes(true)
        },

        selectAllChartAccountIcon() {
            let icon = ''

            if (!this.chartAccount.length) {
                icon = 'mdi-checkbox-blank-outline'
            } else if (this.chartAccount.length < this.chartAccountOption.items.length) {
                icon = 'mdi-minus-box'
            } else {
                icon = 'mdi-close-box'
            }

            return icon
        },
    },

    watch: {
        event (val) {
            if (val) {
                try {
                    this.$http.post(this.$ipApprovalFlow + 'planning-group' + '/list-options',
                        {
                            filter: {
                                conditions: [
                                    {
                                        AndOr: "AND",
                                        column: "id_planning_group_version",
                                        operator: "=",
                                        value: val.id_planning_group_version
                                    }
                                ]
                            }
                        }
                    ).then((res) => {
                        this.planningGroups = res.data.rows
                    })
                } catch (err) {
                    this.$fnError(err)
                }
            }
        },

        planningGroup (val) {
            if (val[0]){
                // this.selectedPlanningGroup = val[0].id
            } else {
                this.levels = []
                this.approvals = []
                this.selectedPlanningGroup = 0
            }
        },

        selectAllToApprove (val) {
            this.selected = []
            if (val) {
                this.selected = this.allActualLevels.filter(level => level.actualLevel.id_status !== 4 && level.actualLevel.enable)
            }
        },
        selectAllToReprove (val) {
            this.selected = []
            if (val) {
                this.selected = this.allActualLevels.filter(level => level.actualLevel.id_level !== 1 && level.actualLevel.enable || level.actualLevel.id_status === 4 && level.actualLevel.enable)
            }
        },
    },

    beforeMount() {
        this.init()
    },

    methods: {
        async init() {
            this.listUserGroups()

            await this.getEvents()
            if (!this.eventOption.items.length) {
                return this.noEventsFound()
            }

            this.setDateColumns()

            await this.getPlanningGroups()
            if (!this.planningGroupOption.items.length) {
                return this.noPlanningGroupsFound()
            }

            await this.getProducts()
            if (!this.productOption.items.length) {
                return this.noProductsFound()
            }

            await this.getCostCenters()

            await this.getDropdownOptions()

            return this.loadGrid()
        },

        async loadGrid() {
            this.loading = true
            this.hotInstance = null
            this.editedRows = []
            this.selectedRowId = null
            this.selectedRow = {}
            this.duplicatingItem = false

            this.noDataMessage = null
            this.noDataBtn = { path: null, text: null }

            if (this.disableLoadGrid) {
                return this.loading = false
            }

            if (this.planningGroup.length) {
                this.selectedEvent = this.event.id
                this.selectedPlanningGroup = this.planningGroup[0].id
                if (this.product[0]) this.selectedProduct = this.product[0].id_product
                this.levels = []
                this.approvals = []
                await this.approvalListLevels()
                await this.approvalHistory()
                await this.setAllLevels()
            }

            this.disableEdition = false
            this.readOnlyRules = []
            if (this.hasMultipleSelection || this.hasEmptyRequiredFilter) {
                this.disableEdition = true
            }
            
            this.readOnly = this.disableEdition || this.readOnlyApproval || this.isShowingFormulas
            if (this.readOnly) this.setReadOnlyRules()

            let payload = this.setPayload()

            try {
                const purchasePlanningRes = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/list', { ...payload })
                if (purchasePlanningRes) {
                    let _colHeaders = []
                    let _columns = []
                    let resColumns = purchasePlanningRes.data.columns
                    this.formulas = purchasePlanningRes.data.formulas

                    purchasePlanningRes.data.headers.forEach((header) => {
                        let headerIndex = parseInt(header.value.slice(5, 7), 10)
                        let columnIndex = resColumns.findIndex(column => column.columnAlias === header.value)
                        if (columnIndex === -1) return

                        let headerText = header.text

                        if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            if (header.value === 'value00') {
                                let values = header.text.split(' ')
                                headerText = `${values[0]} ${values[1] || ''}`
                            }

                            let year = null
                            if (headerIndex > 0 && headerIndex <= 12) {
                                if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                    year = this.year1
                                } else {
                                    year = this.year2
                                }
                                headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            } else if (headerIndex >= 13 && headerIndex <= 24) {
                                if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                    year = this.year2
                                } else {
                                    year = parseInt(this.year2, 10) + 1
                                }
                                headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            }
                        }
                        _colHeaders.push(headerText)

                        let readOnly = false
                        // Aqui modifica readOnly de acordo com id_status
                        this.readOnly ? readOnly = true: readOnly = false

                        if (header.editable === false) {
                            readOnly = true
                        }

                        let type = null
                        let source = null
                        let className = ''
                        let numericFormat = null

                        if (header.options) {
                            let found = this.dropdownOptions.find(o => o.column === header.columnId)

                            if (found) {
                                let itemsText = []

                                if (found.column === 'renewal_month') {
                                    itemsText = found.items
                                } else {
                                    itemsText.push(null)
                                    found.items.forEach(item => {
                                        itemsText.push(item.text)
                                    })
                                }

                                type = 'dropdown'
                                source = itemsText
                            }
                        } else if (header.value === 'contract_quantity') {
                            type = 'numeric'
                            className += ' htRight'
                            numericFormat = {
                                pattern: {
                                    thousandSeparated: true,
                                    negative: "parenthesis",
                                    mantissa: 0,
                                },
                                culture: ptBR.languageCode
                            }
                        } else if (header.value === 'contract_percentage') {
                            type = 'numeric'
                            className += ' htRight'
                            numericFormat = {
                                pattern: {
                                    output: 'percent',
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        } else if (resColumns[columnIndex].columnType === 'INT' || resColumns[columnIndex].columnType === 'DECIMAL') {
                            type = 'numeric'
                            className += ' htRight'
                            numericFormat = {
                                pattern: {
                                    thousandSeparated: true,
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        } else {
                            type = 'text'
                            className += ' htLeft'
                        }

                        let hide = false
                        if (header.hideColumn) {
                            hide = true
                        }

                        const totalColumns = [
                            'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                            'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
                        ]

                        if (totalColumns.includes(header.value)) {
                            className += ' border-sides-1 font-bold'
                        }

                        let width = undefined
                        if (header.value === 'vendor_description') {
                            width = '400'
                        } else if (header.value === 'purchase_description') {
                            width = '500'
                        } else if (header.value === 'purchase_item_description') {
                            width = '500'
                        } else if (header.value === 'purchase_item_code') {
                            width = '400'
                        }

                        _columns.push({
                            data: header.value,
                            readOnly,
                            type,
                            className,
                            numericFormat,
                            source,
                            hide,
                            columnLetter: header.columnLetter,
                            width
                        })
                    })
                    this.colHeaders = _colHeaders
                    this.columns = _columns

                    this.setHiddenColumns()
                    this.setDateReadOnlyColumns()

                    let _rowHeaders = []
                    let _objectData = []
                    this.stylizedRows = []

                    purchasePlanningRes.data.rows.forEach((row, index) => {
                        _rowHeaders.push('')

                        let rowProps = {
                            index,
                            row,
                            className: '',
                            format: row.format,
                            readOnly: false,
                            percent: false,
                        }

                        let subtotalsQty = Object.entries(row).reduce((acc, cur) => {
                            return cur[1] && typeof cur[1] === 'string' && cur[1].includes('Subtotal -') ? acc + 1 : acc + 0
                        }, 0)

                        if (subtotalsQty > 1) {
                            let counter = 0
                            for (const field in row) {
                                if (row[field] && typeof row[field] === 'string' && row[field].includes('Subtotal -')) {
                                    if (counter > 0) {
                                        row[field] = ''
                                    }

                                    counter += 1
                                }
                            }
                        }

                        if (row.indent) {
                            rowProps.className += ' indentedRow'
                            row['account_group_description'] = ' '.repeat(row.indent) + row['account_group_description']
                        }

                        if (row.format.includes('%')) {
                            rowProps.percent = true
                        }

                        if ((row.data_type === 'INT' || row.data_type === 'DECIMAL')) {
                            for (let i = 0; i <= 24; i++) {
                                if (i < 10) {
                                    // if (!row[`value0${i}`].includes('=')) {
                                        row[`value0${i}`] = parseFloat(row[`value0${i}`], 10) || null
                                    // }
                                } else {
                                    // if (!row[`value${i}`].includes('=')) {
                                        row[`value${i}`] = parseFloat(row[`value${i}`], 10) || null
                                    // }
                                }
                            }
                        }

                        if (row.total > 0) {
                            // rowProps.className += ' formulaRow'

                            let foundFormula = this.formulas.find(formula => formula.system_id === row.system_id)
                            let startIndex = this.formulas.indexOf(foundFormula)
                            let endIndex = this.formulas.indexOf(foundFormula) + 23

                            if (foundFormula) {
                                for (let i = startIndex; i <= endIndex; i++) {
                                    row[this.formulas[i].column] = this.formulas[i].excelFormula
                                }
                            }
                        }

                        // if (row.contract_value) {
                        //     row.contract_value = parseFloat(row.contract_value)
                        // }

                        if (row.contract_price) {
                            row.contract_price = parseFloat(row.contract_price)
                        }

                        if (row.contract_percentage) {
                            row.contract_percentage = parseFloat(row.contract_percentage)
                        }

                        if (row.start_date) {
                            row.start_date = moment(row.start_date).utc().format('DD/MM/YYYY')
                        }

                        if (row.end_date) {
                            row.end_date = moment(row.end_date).utc().format('DD/MM/YYYY')
                        }

                        if (row.contract_quantity) {
                            row.contract_quantity = parseFloat(row.contract_quantity)
                        }

                        if (row.css.length) {
                            rowProps.className += ` ${row.css}`
                        }

                        if (row.subtotal) {
                            rowProps.className += ` font-bold`
                        }

                        if (row.editable != 1) {
                            rowProps.readOnly = true
                        }

                        this.stylizedRows.push(rowProps)

                        _objectData.push(row)
                    })
                    this.rowHeaders = _rowHeaders
                    this.objectData = _objectData

                    if (!this.objectData.length) {
                        this.noDataMessage = 'Não há dados disponíveis'
                    }

                    return this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        setColHeaders() {
            if (this.isShowingFormulas) {
                return this.colHeaders.map((colHeader, index) => {
                    return `${colHeader} (${this.columns[index].columnLetter})`
                })
            } else {
                return this.colHeaders
            }
        },

        setPayload() {
            let filter = {
                conditions: []
            }

            if (this.planningGroup.length > 0 && this.planningGroup.length <= this.planningGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_planning_group',
                    operator: 'IN',
                    value: this.planningGroup.map(i => i.id)
                })
            }

            if (this.salesChannel.length > 0 && this.salesChannel.length < this.salesChannelOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_channel',
                    operator: 'IN',
                    value: this.salesChannel.map(i => i.id)
                })
            }

            if (this.salesForce.length > 0 && this.salesForce.length < this.salesForceOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_force',
                    operator: 'IN',
                    value: this.salesForce.map(i => i.id)
                })
            }

            if (this.salesForceBP.length > 0 && this.salesForceBP.length < this.salesForceBPOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_sales_force_bp',
                    operator: 'IN',
                    value: this.salesForceBP.map(i => i.id)
                })
            }

            if (this.customerGroup.length > 0 && this.customerGroup.length < this.customerGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_customer_group',
                    operator: 'IN',
                    value: this.customerGroup.map(i => i.id)
                })
            }

            if (this.costCenter.length > 0 && this.costCenter.length < this.costCenterOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_cost_center',
                    operator: 'IN',
                    value: this.costCenter.map(i => i.id)
                })
            }

            if (this.accountGroup.length > 0 && this.accountGroup.length < this.accountGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_account_group',
                    operator: 'IN',
                    value: this.accountGroup.map(i => i.id)
                })
            }

            if (this.chartAccount.length > 0 && this.chartAccount.length < this.chartAccountOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_chart_account',
                    operator: 'IN',
                    value: this.chartAccount.map(i => i.id)
                })
            }

            if (this.vendor.length === 1 || (this.vendor.length > 0 && this.vendor.length < this.vendorOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_vendor',
                    operator: 'IN',
                    value: this.vendor.map(i => i.id)
                })
            }

            if (this.product.length === 1 || (this.product.length > 0 && this.product.length < this.productOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_product',
                    operator: 'IN',
                    value: this.product.map(i => i.id_product)
                })
            }

            if (this.employee.length === 1 || (this.employee.length > 0 && this.employee.length < this.employeeOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_employee',
                    operator: 'IN',
                    value: this.employee.map(i => i.id)
                })
            }
            
            if (this.purchaseItemCode.length === 1 || (this.purchaseItemCode.length > 0 && this.purchaseItemCode.length < this.purchaseItemCodeOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'purchase_item_code',
                    operator: 'IN',
                    value: this.purchaseItemCode.map(i => i.id)
                })
            }

            if (this.status.length === 1 || (this.status.length > 0 && this.status.length < this.statusOption.items.length)) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_status',
                    operator: 'IN',
                    value: this.status.map(i => i.id)
                })
            }

            return {
                id_event: this.event.id,
                id_account_group_version: this.event.id_account_group_version,
                filter,
                showAccountGroup: true,
                showPurchaseChannel: this.showPurchaseChannel,
                showSalesChannel: this.showSalesChannel,
                showSalesForce: this.showSalesForce,
                showSalesForceBp: this.showSalesForceBP,
                showCustomerGroup: this.showCustomerGroup,
                showCostCenter: this.showCostCenter,
                showVendor: this.showVendor,
                showChartAccount: this.showChartAccount,
                showProduct: this.showProduct,
                showEmployee: this.showEmployee,
                showPurchaseDescription: this.showPurchaseDescription,
                showMarketIndex: this.showMarketIndex,
                showPurchaseItem: this.showPurchaseItemCode,
                showPaymentCondition: this.showPaymentCondition,
                showStatus: this.showStatus,
                showCurrency: this.showCurrency,
                showRenewalMonth: this.showRenewalMonth,
                showStartDate: this.showStartDate,
                showEndDate: this.showEndDate,
                showContractValue: this.showContractValue,
                showContractQuantity: this.showContractQuantity,
                showContractPercentage: this.showContractPercentage,
                showContractPrice: this.showContractPrice,
                showCdIdErp: this.showCdIdErp,
            }
        },

        async saveData() {
            this.saving = true
            let payload = { items: [] }

            for (let i = 0; i < this.objectData.length; i++) {
                if (this.editedRows.includes(i)) {
                    let object = {}

                    for (const key in this.objectData[i]) {
                        if (key === 'start_date' || key === 'end_date') {
                            let value = this.objectData[i][key]
                            if (value) {
                                let day = value.slice(0, 2)
                                let month = value.slice(3, 5) - 1
                                let year = value.slice(-4)
                                let date = moment(new Date(year, month, day)).format('YYYY-MM-DD')

                                object[key] = date
                            }
                        } else {
                            object[key] = this.hotInstance.getDataAtRowProp(i, key)
                        }
                    }

                    payload.items.push(object)
                }
            }

            try {
                const res = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/mass-save', payload)
                if (res) {
                    this.editedRows = []
                    this.$toast.success(res.data.msg)
                    this.saving = false
                    this.loadGrid()
                    return 'success'
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
                return 'error'
            }
        },

        async renew(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogRenewGrid = true
            }

            this.loading = true

            this.dialogRenewGrid = false
            this.hotInstance = null
            this.selectedRowId = null
            this.selectedRow = {}
            this.duplicatingItem = false

            this.eventOption.items = []
            this.event = []
            this.loadingEvent = true

            this.planningGroupOption.items = []
            this.planningGroup = []
            this.loadingPlanningGroup = true

            this.productOption.items = []
            this.product = []
            this.loadingProduct = true

            this.salesChannelOption.items = []
            this.salesChannel = []

            this.salesForceOption.items = []
            this.salesForce = []

            this.salesForceBPOption.items = []
            this.salesForceBP = []

            this.customerGroupOption.items = []
            this.customerGroup = []

            this.costCenterOption.items = []
            this.costCenter = []

            this.accountGroupOption.items = []
            this.accountGroup = []

            this.chartAccountOption.items = []
            this.chartAccount = []

            this.vendorOption.items = []
            this.vendor = []

            this.employeeOption.items = []
            this.employee = []

            this.purchaseItemCodeOption.items = []
            this.purchaseItemCode = []

            this.statusOption.items = []
            this.status = []

            return this.init()
        },

        async refresh(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogRefreshGrid = true
            }

            this.loading = true
            this.dialogRefreshGrid = false
            this.hotInstance = null
            this.selectedRowId = null
            this.selectedRow = {}
            this.duplicatingItem = false
            
            await this.getDropdownOptions()
            
            return this.loadGrid()
        },

        async closeGrid(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogCloseGrid = true
            }
            
            return this.$router.push('/')
        },

        setReadOnlyRules() {
            if (this.isShowingFormulas) {
                this.readOnlyRules.push('Exibindo fórmulas')
            }

            if (this.readOnlyApproval) {
                if (this.actualLevel && this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4) {
                    this.readOnlyRules.push('Nível de aprovação não permite alterações')
                } else {
                    this.readOnlyRules.push('Evento com todos os níveis aprovados')
                }
            }
        },

        async getDropdownOptions() {
            for (const option of this.dropdownOptions) {
                if (!option.items.length) {
                    try {
                        let payload = {
                            filter: {
                                conditions: []
                            }
                        }

                        if (option.conditions?.length) {
                            payload.filter.conditions = option.conditions
                        }

                        let pathMethod = '/list-options'
                        if (option.endpoint[2]) pathMethod = option.endpoint[2]
                        
                        let res = await this.$http.post(option.endpoint[0] + option.endpoint[1] + pathMethod, payload)
                        if (res) {
                            option.items = res.data.rows
                        }
                        
                    } catch (err) {
                        this.$fnError(err)
                    }
                }
            }
        },

        async massDeleteItemConfirm() {
            this.deleting = true

            let items = []
            let payload = {
                delete: {}
            }

            if (this.selectedRowId.length) {
                this.selectedRowId.sort()
                let firstRow = this.selectedRowId[0]
                let lastRow = this.selectedRowId[1]

                for (let i = firstRow; i <= lastRow; i++) {
                    items.push({ id_purchase_planning: this.objectData[i].id_purchase_planning })
                }

                payload.delete = { items }
            } else {
                items.push({ id_purchase_planning: this.objectData[this.selectedRowId].id_purchase_planning })
                payload.delete = { items }
            }

            try {
                const res = await this.$http.post(`${this.$ipPurchasePlanning}purchase-planning/mass-effect`, payload)
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.loadGrid()
                    this.dialogMassDelete = false
                    this.deleting = false
                    this.selectedRowId = null
                    this.selectedRow = {}
                }
            } catch (err) {
                this.$fnError(err)
                this.deleting = false
            }
        },

        async getEvents() {
            this.loadingEvent = true

            let filter = {
                orderColumn: "description",
                textColumn: "description",
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_module",
                        operator: "=",
                        value: 6
                    },
                    {
                        AndOr: "AND",
                        column: "id_status",
                        operator: "NOT LIKE",
                        value: 2
                    },
                    {
                        AndOr: "OR",
                        column: "id_planning_function",
                        operator: "=",
                        value: 4
                    },
                ]
            }

            try {
                const eventOption = this.options.find(option => option.column === 'id_event')
                const eventRes = await this.$http.post(`${eventOption.endpoint[0]}${eventOption.endpoint[1]}/${eventOption.endpoint[2] ? eventOption.endpoint[2] : 'list-options'}`, { filter })
                if (eventRes) {
                    eventOption.items = eventRes.data.rows
                    let foundFirst = eventOption.items.find((item, index) => index === 0)
                    this.event = foundFirst
                    this.year1 = this.event.fiscal_year
                    this.yearMonthInputStart = this.event.year_month_input_start
                    this.fiscalYearStartMonth = this.event.fiscal_year_start_month
                    this.startingMonthIndex = this.event.fiscal_year_start_month - 1
                    this.year2 = this.year1 + 1

                    this.loadingEvent = false
                }
            } catch (err) {
                this.loadingEvent = false
                this.$fnError(err)
            }
        },

        closeDialogEvent() {
            this.event = this.backupEvent
            this.dialogEvent = false
        },

        async selectEvent(save = false) {
            if (save) {
                const saved = await this.saveData()
                if (saved === 'error') return
            }

            if (this.unsavedChanges && !this.isShowingFormulas) {
                return this.dialogEvent = true  
            }

            this.loading = true
            this.dialogEvent = false

            this.year1 = this.event.fiscal_year
            this.yearMonthInputStart = this.event.year_month_input_start
            this.fiscalYearStartMonth = this.event.fiscal_year_start_month
            this.startingMonthIndex = this.event.fiscal_year_start_month - 1
            this.year2 = this.year1 + 1

            this.setDateColumns()

            this.planningGroupOption.items = []
            this.planningGroup = []
            this.loadingPlanningGroup = true

            this.productOption.items = []
            this.product = []
            this.loadingProduct = true

            await this.getPlanningGroups()
            if (!this.planningGroupOption.items.length) {
                return this.noPlanningGroupsFound()
            }

            await this.getProducts()
            if (!this.productOption.items.length) {
                return this.noProductsFound()
            }

            await this.getCostCenters()

            await this.getDropdownOptions()

            return this.loadGrid()
        },

        noEventsFound() {
            this.event = null
            this.planningGroup = []
            this.salesChannel = []
            this.salesForce = []
            this.salesForceBP = []
            this.customerGroup = []
            this.costCenter = []
            this.accountGroup = []
            this.chartAccount = []
            this.vendor = []
            this.product = []
            this.employee = []

            this.noDataMessage = 'Cadastre um evento para iniciar o planejamento'
            this.noDataBtn.path = '/event'
            this.noDataBtn.text = 'Cadastrar evento'

            this.loading = false
        },

        async getPlanningGroups() {
            this.loadingPlanningGroup = true

            let filter = {
                conditions: [
                    {
                        AndOr: "AND",
                        column: "id_planning_group_version",
                        operator: "=",
                        value: this.event.id_planning_group_version
                    }
                ]
            }

            try {
                const planningGroupRes = await this.$http.post(`${this.planningGroupOption.endpoint[0]}${this.planningGroupOption.endpoint[1]}/${this.planningGroupOption.endpoint[2] ? this.planningGroupOption.endpoint[2] : 'list-options'}`, { filter })
                if (planningGroupRes) {
                    this.planningGroupOption.items = planningGroupRes.data.rows

                    let foundFirst = this.planningGroupOption.items.find((item, index) => index === 0)
                    this.planningGroup.push(foundFirst)
                    
                    this.loadingPlanningGroup = false
                }
            } catch (err) {
                this.loadingPlanningGroup = false
                this.$fnError(err)
            }
        },

        setAllPlanningGroups() {
            if (!this.planningGroup.length) {
                this.planningGroup = lodash.cloneDeep(this.planningGroupOption.items)
            } else {
                this.planningGroup = []
            }
        },

        setBackupPlanningGroup() {
            this.backupPlanningGroup = lodash.cloneDeep(this.planningGroup)
        },

        async confirmPlanningGroupSelection() {
            if (lodash.isEqual(this.planningGroup, this.backupPlanningGroup)) {
                return
            }

            this.productOption.items = []
            this.product = []
            this.loadingProduct = true

            await this.getProducts()
            if (!this.productOption.items.length) {
                return this.noProductsFound()
            }
        },

        noPlanningGroupsFound() {
            this.planningGroup = []
            this.loadingPlanningGroup = false

            this.product = []
            this.loadingProduct = false

            this.noDataMessage = 'Cadastre um grupo de planejamento'
            this.noDataBtn.path = '/planning-group'
            this.noDataBtn.text = 'Cadastrar grupo de planejamento'

            this.loading = false
        },

        async getSalesChannels() {
            this.loadingSalesChannel = true

            let filter = { conditions: [] }
            filter.conditions.push(
                {
                    AndOr: 'AND',
                    column: 'id_sales_channel_type',
                    operator: '=',
                    value: this.event.id_sales_channel_type
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesChannelRes = await this.$http.post(`${this.salesChannelOption.endpoint[0]}${this.salesChannelOption.endpoint[1]}/${this.salesChannelOption.endpoint[2] ? this.salesChannelOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesChannelRes) {
                    this.salesChannelOption.items = salesChannelRes.data.rows

                    // let foundFirst = this.salesChannelOption.items.find((item, index) => index === 0)
                    // this.salesChannel.push(foundFirst)

                    this.loadingSalesChannel = false
                } 
            } catch (err) {
                this.loadingSalesChannel = false
                this.$fnError(err)
            }
        },

        setAllSalesChannels() {
            if (!this.salesChannel.length) {
                this.salesChannel = lodash.cloneDeep(this.salesChannelOption.items)
            } else {
                this.salesChannel = []
            }
        },

        async getSalesForces() {
            this.loadingSalesForce = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",   
                    column: "id_sales_force_type",
                    operator: "=",
                    value: this.event.id_sales_force_type
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesForceRes = await this.$http.post(`${this.salesForceOption.endpoint[0]}${this.salesForceOption.endpoint[1]}/${this.salesForceOption.endpoint[2] ? this.salesForceOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesForceRes) {
                    this.salesForceOption.items = salesForceRes.data.rows

                    // let foundFirst = this.salesForceOption.items.find((item, index) => index === 0)
                    // this.salesForce.push(foundFirst)

                    this.loadingSalesForce = false
                } 
            } catch (err) {
                this.loadingSalesForce = false
                this.$fnError(err)
            }
        },

        setAllSalesForces() {
            if (!this.salesForce.length) {
                this.salesForce = lodash.cloneDeep(this.salesForceOption.items)
            } else {
                this.salesForce = []
            }
        },

        async getSalesForceBPs() {
            this.loadingSalesForceBP = true

            let filter = { conditions: [] }

            filter.conditions.push(
                {
                    AndOr: "AND",   
                    column: "id_sales_force_type",
                    operator: "=",
                    value: 7
                },
                {
                    AndOr: "AND",
                    column: "id_planning_group_version",
                    operator: "=",
                    value: this.event.id_planning_group_version
                },
                {
                    AndOr: "AND",
                    column: "id_event_type",
                    operator: "=",
                    value: this.event.id_event_type
                },
                {
                    AndOr: "AND",
                    column: "fiscal_year",
                    operator: "=",
                    value: this.event.fiscal_year
                }
            )

            try {
                const salesForceBPRes = await this.$http.post(`${this.salesForceBPOption.endpoint[0]}${this.salesForceBPOption.endpoint[1]}/${this.salesForceBPOption.endpoint[2] ? this.salesForceBPOption.endpoint[2] : 'list-options'}`, { filter })
                if (salesForceBPRes) {
                    this.salesForceBPOption.items = salesForceBPRes.data.rows

                    // let foundFirst = this.salesForceBPOption.items.find((item, index) => index === 0)
                    // this.salesForceBP.push(foundFirst)

                    this.loadingSalesForceBP = false
                } 
            } catch (err) {
                this.loadingSalesForceBP = false
                this.$fnError(err)
            }
        },

        setAllSalesForceBPs() {
            if (!this.salesForceBP.length) {
                this.salesForceBP = lodash.cloneDeep(this.salesForceBPOption.items)
            } else {
                this.salesForceBP = []
            }
        },

        async getCustomerGroups() {
            this.loadingCustomerGroup = true

            try {
                const customerGroupRes = await this.$http.post(`${this.customerGroupOption.endpoint[0]}${this.customerGroupOption.endpoint[1]}/${this.customerGroupOption.endpoint[2] ? this.customerGroupOption.endpoint[2] : 'list-options'}`, { })
                if (customerGroupRes) {
                    this.customerGroupOption.items = customerGroupRes.data.rows

                    // let foundFirst = this.customerGroupOption.items.find((item, index) => index === 0)
                    // this.customerGroup.push(foundFirst)

                    this.loadingCustomerGroup = false
                } 
            } catch (err) {
                this.loadingCustomerGroup = false
                this.$fnError(err)
            }
        },

        setAllCustomerGroups() {
            if (!this.customerGroup.length) {
                this.customerGroup = lodash.cloneDeep(this.customerGroupOption.items)
            } else {
                this.customerGroup = []
            }
        },

        async getCostCenters() {
            this.loadingCostCenter = true

            this.costCenterOption.items = []

            let filter = {
                conditions: this.costCenterOption.conditions
            }

            filter.conditions.find(i => i.column === 'id_company_group').value = this.event.id_company_group
            
            try {
                const costCenterRes = await this.$http.post(this.costCenterOption.endpoint[0] + this.costCenterOption.endpoint[1] + '/list-options', { id_event: this.event.id, filter })
                if (costCenterRes) {
                    this.costCenterOption.items = costCenterRes.data.rows
                    this.loadingCostCenter = false
                } 
            } catch (err) {
                this.loadingCostCenter = false
                this.$fnError(err)
            }
        },

        setAllCostCenters() {
            if (!this.costCenter.length) {
                this.costCenter = lodash.cloneDeep(this.costCenterOption.items)
            } else {
                this.costCenter = []
            }
        },

        async getAccountGroups() {
            this.loadingAccountGroup = true

            let filter = { conditions: [] }

            filter.conditions.push({
                AndOr: 'AND',
                column: 'id_account_group_version',
                operator: '=',
                value: this.event.id_account_group_version
            })

            try {
                const accountGroupRes = await this.$http.post(this.accountGroupOption.endpoint[0] + this.accountGroupOption.endpoint[1] + '/list-options', { filter })
                if (accountGroupRes) {
                    this.accountGroupOption.items = accountGroupRes.data.rows
                    this.loadingAccountGroup = false
                } 
            } catch (err) {
                this.loadingAccountGroup = false
                this.$fnError(err)
            }
        },

        setAllAccountGroups() {
            if (!this.accountGroup.length) {
                this.accountGroup = lodash.cloneDeep(this.accountGroupOption.items)
            } else {
                this.accountGroup = []
            }
        },

        async getChartAccounts() {
            this.loadingChartAccount = true

            let filter = { conditions: [] }

            if (this.accountGroup.length > 0 && this.accountGroup.length < this.accountGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_account_group',
                    operator: 'IN',
                    value: this.accountGroup.map(i => i.id)
                })
            }

            try {
                const chartAccountRes = await this.$http.post(this.chartAccountOption.endpoint[0] + this.chartAccountOption.endpoint[1] + '/list-options', { filter })
                if (chartAccountRes) {
                    this.chartAccountOption.items = chartAccountRes.data.rows
                    this.loadingChartAccount = false
                } 
            } catch (err) {
                this.loadingChartAccount = false
                this.$fnError(err)
            }
        },

        setAllChartAccounts() {
            if (!this.chartAccount.length) {
                this.chartAccount = lodash.cloneDeep(this.chartAccountOption.items)
            } else {
                this.chartAccount = []
            }
        },

        async getVendors() {
            this.loadingVendor = true

            let filter = { conditions: [] }
                
            try {
                const vendorRes = await this.$http.post(this.vendorOption.endpoint[0] + this.vendorOption.endpoint[1] + '/list-options-purchase-planning', { id_event: this.event.id, filter })
                if (vendorRes) {
                    this.vendorOption.items = vendorRes.data.rows

                    // let foundFirst = this.vendorOption.items.find((item, index) => index === 0)
                    // this.vendor.push(foundFirst)
                    this.loadingVendor = false
                }
            } catch (err) {
                this.loadingVendor = false
                this.$fnError(err)
            }
        },

        setAllVendors() {
            if (!this.vendor.length) {
                this.vendor = lodash.cloneDeep(this.vendorOption.items)
            } else {
                this.vendor = []
            }
        },

        async getProducts() {
            this.loadingProduct = true

            let filter = { conditions: [] }

            if (this.event) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_event_type',
                    operator: '=',
                    value: this.event.id_event_type
                })

                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'fiscal_year',
                    operator: '=',
                    value: this.event.fiscal_year
                })
            }

            if (this.planningGroup.length > 0 && this.planningGroup.length <= this.planningGroupOption.items.length) {
                filter.conditions.push({
                    AndOr: 'AND',
                    column: 'id_planning_group',
                    operator: 'IN',
                    value: this.planningGroup.map(i => i.id)
                })
            }
           
            let listMethod = '/list-options'
            if (this.productOption.endpoint[2]) listMethod = this.productOption.endpoint[2]

            try {
                const productRes = await this.$http.post(this.productOption.endpoint[0] + this.productOption.endpoint[1] + listMethod, { filter })
                if (productRes) {
                    this.productOption.items = productRes.data.rows
                    this.loadingProduct = false
                }
            } catch (err) {
                this.loadingProduct = false
                this.$fnError(err)
            }
        },

        setAllProducts() {
            if (!this.product.length) {
                this.product = lodash.cloneDeep(this.productOption.items)
            } else {
                this.product = []
            }
        },

        noProductsFound() {
            this.product = []

            this.noDataMessage = 'Cadastre um contrato para iniciar o planejamento'
            this.noDataBtn.path = '/product-purchase-planning'
            this.noDataBtn.text = 'Cadastrar contrato'

            this.loading = false
            this.loadingProduct = false
        },

        async getEmployees() {
            this.loadingEmployee = true

            let filter = { conditions: [] }
                
            try {
                const employeeRes = await this.$http.post(this.employeeOption.endpoint[0] + this.employeeOption.endpoint[1] + '/list-options-purchase-planning', { id_event: this.event.id, filter })
                if (employeeRes) {
                    this.employeeOption.items = employeeRes.data.rows

                    // let foundFirst = this.employeeOption.items.find((item, index) => index === 0)
                    // this.employee.push(foundFirst)
                    this.loadingEmployee = false
                }
            } catch (err) {
                this.loadingEmployee = false
                this.$fnError(err)
            }
        },

        setAllEmployees() {
            if (!this.employee.length) {
                this.employee = lodash.cloneDeep(this.employeeOption.items)
            } else {
                this.employee = []
            }
        },

        async getPurchaseItemCodes() {
            this.loadingPurchaseItemCode = true

            let filter = { conditions: [] }
                
            try {
                const purchaseItemCodeRes = await this.$http.post(this.purchaseItemCodeOption.endpoint[0] + this.purchaseItemCodeOption.endpoint[1] + '/list-options', { filter })
                if (purchaseItemCodeRes) {
                    this.purchaseItemCodeOption.items = purchaseItemCodeRes.data.rows

                    // let foundFirst = this.purchaseItemCodeOption.items.find((item, index) => index === 0)
                    // this.purchaseItemCode.push(foundFirst)
                    this.loadingPurchaseItemCode = false
                }
            } catch (err) {
                this.loadingPurchaseItemCode = false
                this.$fnError(err)
            }
        },

        setAllPurchaseItemCodes() {
            if (!this.purchaseItemCode.length) {
                this.purchaseItemCode = lodash.cloneDeep(this.purchaseItemCodeOption.items)
            } else {
                this.purchaseItemCode = []
            }
        },

        async getStatus() {
            this.loadingStatus = true

            let filter = { conditions: [] }

            filter.conditions = this.statusOption.conditions
                
            try {
                const statusRes = await this.$http.post(this.statusOption.endpoint[0] + this.statusOption.endpoint[1] + '/list-options', { filter })
                if (statusRes) {
                    this.statusOption.items = statusRes.data.rows
                    this.loadingStatus = false
                }
            } catch (err) {
                this.loadingStatus = false
                this.$fnError(err)
            }
        },

        setAllStatus() {
            if (!this.status.length) {
                this.status = lodash.cloneDeep(this.statusOption.items)
            } else {
                this.status = []
            }
        },

        createItem() {
            this.selectedRow = {}
            this.itemDialog = true
        },

        editItem() {
            this.selectedRow = this.objectData[this.selectedRowId]
            this.itemDialog = true
        },

        duplicateItem() {
            this.duplicatingItem = true
            this.selectedRow = this.objectData[this.selectedRowId]
            this.itemDialog = true
        },

        closeItemDialog() {
            this.duplicatingItem = false
            this.itemDialog = false
        },

        openDialogRatio() {
            this.ratioCostCenterItems = []
            const { items } = this.costCenterOption

            const removedDuplicates = Array.from(new Set(items.map(i => i.id)))
                .map(id => { return items.find(i => i.id === id) })

            this.ratioCostCenterItems = lodash.cloneDeep(removedDuplicates)
            this.selectedRatioCostCenterItems = []
            this.dialogRatio = true
        },

        selectRatioCostCenterItem(item, index) {
            this.ratioCostCenterItems.splice(index, 1)
            this.selectedRatioCostCenterItems.push(item)
        },

        selectAllRatioCostCenterItems() {
            this.selectedRatioCostCenterItems = this.selectedRatioCostCenterItems.concat(this.ratioCostCenterItems)
            this.ratioCostCenterItems = []
        },

        removeRatioCostCenterItem(item, index) {
            this.selectedRatioCostCenterItems.splice(index, 1)
            this.ratioCostCenterItems.push(item)
        },

        removeAllRatioCostCenterItems() {
            this.ratioCostCenterItems = this.ratioCostCenterItems.concat(this.selectedRatioCostCenterItems)
            this.selectedRatioCostCenterItems = []
        },

        setDateColumns() {
            this.startingYear = true
            this.year1month = true
            this.year1quarter = false
            this.year1semester = false
            this.laterYear = false
            this.year2month = false
            this.year2quarter = false
            this.year2semester = false

            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()
            let _months = []
            this.months.forEach(month => {
                if (month.id - 1 < this.startingMonthIndex) {
                    _months.splice(this.startingMonthIndex + month.id - 1, 0, month)
                } else {
                    _months.splice(month.id - 1 - this.startingMonthIndex, 0, month)
                }
            })
            this.months = _months
            this.year1months = this.months
            // this.year2months = this.months
            // this.year1quarters = this.quarters
            // this.year2quarters = this.quarters
            // this.year1semesters = this.semesters
            // this.year2semesters = this.semesters
        },

        handleSetAllHiddenColumns() {
            if (this.hasMultipleColumns) {
                this.showPurchaseChannel = false
                this.showSalesChannel = false
                this.showSalesForce = false
                this.showSalesForceBP = false
                this.showCustomerGroup = false
                this.showCostCenter = false
                this.showVendor = false
                this.showChartAccount = false
                this.showPurchaseDescription = false
                this.showProduct = false
                this.showEmployee = false
                this.showPurchaseItemCode = false
                this.showPaymentCondition = false
                this.showStatus = false
                this.showCurrency = false
                this.showMarketIndex = false
                this.showRenewalMonth = false
                this.showStartDate = false
                this.showEndDate = false
                // this.showContractValue = false
                // this.showContractPercentage = false
                // this.showContractPrice = false
                // this.showContractQuantity = false
                this.showCdIdErp = false
            } else {
                this.showPurchaseChannel = true
                this.showSalesChannel = true
                this.showSalesForce = true
                this.showSalesForceBP = true
                this.showCustomerGroup = true
                this.showCostCenter = true
                this.showVendor = true
                this.showChartAccount = true
                this.showPurchaseDescription = true
                this.showProduct = true
                this.showEmployee = true
                this.showPurchaseItemCode = true
                this.showPaymentCondition = true
                this.showStatus = true
                this.showCurrency = true
                this.showMarketIndex = true
                this.showRenewalMonth = true
                this.showStartDate = true
                this.showEndDate = true
                // this.showContractValue = true
                // this.showContractQuantity = true
                this.showContractPercentage = true
                // this.showContractPrice = true
                this.showCdIdErp = true
            }
        },

        setHiddenColumns() {
            let _hiddenColumns = { columns: [], copyPasteEnabled: true }

            this.columns.forEach((column, index) => {
                if (column.hide) {
                    _hiddenColumns.columns.push(index)
                }
            })

            this.hiddenColumns = _hiddenColumns

            let cols = []

            for (let i = 1; i <= 12; i++) {
                let col = null
                let foundMonth = this.year1months.find(m => m.id === i)
                
                if (!foundMonth || !this.year1month) {
                    if (i < 10) {
                        col = this.columns.findIndex(c => c.data === `value0${i}`)
                    } else {
                        col = this.columns.findIndex(c => c.data === `value${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 13; i <= 24; i++) {
                let col = null
                let foundMonth = this.year2months.find(m => m.id === i - 12)
                
                if (!foundMonth || !this.year2month) {
                    col = this.columns.findIndex(c => c.data === `value${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter || !this.year1quarter) {
                    col = this.columns.findIndex(c => c.data === `year1quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter || !this.year2quarter) {
                    col = this.columns.findIndex(c => c.data === `year2quarter${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year1semesters.find(s => s.id === i)
                
                if (!foundSemester || !this.year1semester) {
                    col = this.columns.findIndex(c => c.data === `year1semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester || !this.year2semester) {
                    col = this.columns.findIndex(c => c.data === `year2semester${i}`)
                }

                if (col) {
                    cols.push(col)
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null

                if (i === 1) {
                    if (!this.startingYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                } else if (i === 2) {
                    if (!this.laterYear) {
                        col = this.columns.findIndex(c => c.data === `year${i}`)
                    }
                }

                if (col) {
                    cols.push(col)
                }
            }

            this.hiddenColumns.columns.push(...cols)
        },

        setDateReadOnlyColumns() {
            const monthInputStart = parseInt((this.yearMonthInputStart).toString().slice(4, 6))
            const difference = monthInputStart - this.fiscalYearStartMonth

            this.readOnlyColumns = []

            // verify if month index is before company fiscal year
            if (difference > 0) {
                for (let i = 1; i <= difference; i++) {
                    let col = null

                    if (i < 10) {
                        col = `value0${i}`
                    } else {
                        col = `value${i}`
                    }

                    let foundColumn = this.columns.find(c => c.data === col)
                    let foundColumnIndex = this.columns.findIndex(c => c.data === col)
                    if (foundColumn) {
                        foundColumn.readOnly = true
                        foundColumn.className += ' read-only-column'
                        this.readOnlyColumns.push(foundColumnIndex)
                    }
                }
            }

            const totalColumns = [
                'year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2'
            ]

            // verify if column is readOnly
            this.columns.forEach((c, i) => {
                if (totalColumns.includes(c.data) && c.readOnly){
                    this.readOnlyColumns.push(i)
                }
            })
        },

        showAllMonths(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }
                    cols.push(col)
                }

                if (this.year1month) {
                    this.year1months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year1months = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    col = this.hotInstance.propToCol(`value${i}`)
                    cols.push(col)
                }

                if (this.year2month) {
                    this.year2months = this.months
                    plugin.showColumns(cols)
                } else {
                    this.year2months = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showMonth(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = null

            if (year === 1) {
                for (let i = 1; i <= 12; i++) {
                    let index = i + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year1months.find(month => month.id === index)
                    if (i < 10) {
                        col = this.hotInstance.propToCol(`value0${i}`)
                    } else {
                        col = this.hotInstance.propToCol(`value${i}`)
                    }

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            } else if (year === 2) {
                for (let i = 13; i <= 24; i++) {
                    let index = i - 12 + this.startingMonthIndex
                    if (index > 12) index -= 12
                    let foundMonth = this.year2months.find(month => month.id === index)
                    col = this.hotInstance.propToCol(`value${i}`)

                    if (foundMonth) {
                        plugin.showColumn(col)
                    } else {
                        plugin.hideColumn(col)
                    }
                }
            }

            this.year1months.length ? this.year1month = true : this.year1month = false
            this.year2months.length ? this.year2month = true : this.year2month = false
            
            this.hotInstance.render()
        },

        showAllQuarters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 4; i++) {
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1quarter) {
                    this.year1quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year1quarters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2quarter) {
                    this.year2quarters = this.quarters
                    plugin.showColumns(cols)
                } else {
                    this.year2quarters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showQuarter(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1quarters
            } else if ( year === 2){
                array = this.year2quarters
            }

            for (let i = 1; i <= 4; i++) {
                let foundQuarter = array.find(quarter => quarter.id === i)
                col = this.hotInstance.propToCol(`year${year}quarter${i}`)
                if (foundQuarter) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1quarters.length ? this.year1quarter = true : this.year1quarter = false
            this.year2quarters.length ? this.year2quarter = true : this.year2quarter = false
            
            this.hotInstance.render()
        },

        showAllSemesters(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let cols = []
            let col = null

            for (let i = 1; i <= 2; i++) {
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                cols.push(col)
            }
            
            if (year === 1) {
                if (this.year1semester) {
                    this.year1semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year1semesters = []
                    plugin.hideColumns(cols)
                }
            } else if (year === 2) {
                if (this.year2semester) {
                    this.year2semesters = this.semesters
                    plugin.showColumns(cols)
                } else {
                    this.year2semesters = []
                    plugin.hideColumns(cols)
                }
            }

            this.hotInstance.render()
        },

        showSemester(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let array = null
            let col = null

            if (year === 1) {
                array = this.year1semesters
            } else if ( year === 2){
                array = this.year2semesters
            }

            for (let i = 1; i <= 2; i++) {
                let foundSemester = array.find(semester => semester.id === i)
                col = this.hotInstance.propToCol(`year${year}semester${i}`)
                if (foundSemester) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.year1semesters.length ? this.year1semester = true : this.year1semester = false
            this.year2semesters.length ? this.year2semester = true : this.year2semester = false
            
            this.hotInstance.render()
        },

        showYear(year) {
            let plugin = this.hotInstance.getPlugin('hiddenColumns')
            let col = this.hotInstance.propToCol(`year${year}`)

            if (year === 1) {
                if (this.startingYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            } else if (year === 2) {
                if (this.laterYear) {
                    plugin.showColumn(col)
                } else {
                    plugin.hideColumn(col)
                }
            }

            this.hotInstance.render()
        },

        showFormulas() {
            this.isShowingFormulas = !this.isShowingFormulas

            if (this.isShowingFormulas) {
                this.backupColHeaders = lodash.cloneDeep(this.colHeaders)
                this.backupReadOnly = this.readOnly
                this.colHeaders = true
            } else {
                this.colHeaders = this.backupColHeaders
                this.readOnly = this.backupReadOnly
            }

            return this.loadGrid()
        },

        showStyles() {
            this.isShowingStyles = !this.isShowingStyles

            return this.loadGrid()
        },

        async exportSheet(){
            this.exporting = true

            let payload = this.setPayload()
            payload['columnsToRemove'] = this.setColumnsToRemove()

            try {
                const res = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning/report', { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        setColumnsToRemove() {
            let columnsToRemove = []

            // year 1 months
            for (let i = 1; i <= 12; i++) {
                let foundMonth = this.year1months.find(m => m.id === i)

                let index = i < 10 ? `0${i}` : i

                if (!foundMonth) {
                    columnsToRemove.push(`value${index}`)
                }
            }

            // year 2 months
            for (let i = 13; i <= 24; i++) {
                let foundMonth = this.year2months.find(m => m.id === i - 12)

                if (!foundMonth) {
                    columnsToRemove.push(`value${i}`)
                }
            }

            // year 1 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year1quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year1quarter${i}`)
                }
            }

            // year 2 quarters
            for (let i = 1; i <= 4; i++) {
                let foundQuarter = this.year2quarters.find(q => q.id === i)

                if (!foundQuarter) {
                    columnsToRemove.push(`year2quarter${i}`)
                }
            }

            // year 1 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year1semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year1semester${i}`)
                }
            }

            // year 2 semesters
            for (let i = 1; i <= 2; i++) {
                let foundSemester = this.year2semesters.find(s => s.id === i)

                if (!foundSemester) {
                    columnsToRemove.push(`year2semester${i}`)
                }
            }

            // years
            if (!this.startingYear) {
                columnsToRemove.push(`year1`)
            }

            if (!this.laterYear) {
                columnsToRemove.push(`year2`)
            }

            return columnsToRemove
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        async onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                try {
                    const uploadRes = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/upload-spreadsheet', body, { headers: { 'Content-Type': 'multipart/form-data' }})
                    if (uploadRes) {
                        // const massEffectRes = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning' + '/mass-effect', { ...uploadRes.data.id })
                        // if (massEffectRes) {
                            this.$toast.success(uploadRes.data.msg)
                            this.$refs.uploader.value = null
                            this.importing = false
                            this.refresh(false)
                        // }
                    }
                } catch (err) {
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                }
            }
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        addPreventCloseHandler() {
            window.addEventListener('beforeunload', this.preventCloseWindow)
        },

        removePreventCloseHandler() {
            window.removeEventListener('beforeunload', this.preventCloseWindow)
        },

        preventCloseWindow(e) {
            if (this.unsavedChanges && !this.isShowingFormulas) {
                e.preventDefault()
                e.returnValue = ''
            }
        },

        async approvalSelect (option) {
            const payload = {
                items: [
                    {
                        id_event: this.selectedEvent,
                        id_planning_group: this.selectedPlanningGroup,
                        id_product: this.selectedProduct,
                        justification: this.justification
                    }
                ]
            }

            if (!payload.items[0].id_event || !payload.items[0].id_planning_group || !payload.items[0].id_product) {
                return
            }

            try {
                this.loadingApproval = true
                if (option === 1) {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/approve', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } else {
                    const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/disapprove', { ...payload })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
            this.dialogApproval = false
            this.loadingApproval = false
            this.justification = ''
            this.refresh()
        },

        async approvalListLevels () {
            let unapprovedLevels = []
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: this.selectedEvent
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: this.selectedPlanningGroup
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_product',
                            operator: '=',
                            value: this.product[0] ? this.product[0].id_product : null
                        }
                    ]
                }
            }

            if (payload.filter.conditions.find(condition => !condition.value)) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-levels', { ...payload })
                if (res.data.rows.length > 0) {
                    this.levels = res.data.rows
                    this.levels.forEach((level) => {
                        level.date ? level.date = moment(level.date).format('DD/MM/YYYY HH:mm:ss') : false
                        level.id_status === 5 || !level.id_status ? unapprovedLevels.push(level) : false
                    })
                    unapprovedLevels.length < 1 ? this.actualLevel = this.levels[this.levels.length - 1] : this.actualLevel = unapprovedLevels[0]
                    this.userGroups.forEach((group) => {
                        if (group.id_group === this.actualLevel.id_group) {
                            this.disableApprove = false
                        }
                    })
                    await this.getlevels()
                } else {
                    this.readOnlyApproval = false
                }
            } catch (err) {
                this.$fnError(err)
            }
        }, 

        async approvalHistory () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: this.selectedEvent
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: this.selectedPlanningGroup
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_product',
                            operator: '=',
                            value: this.product[0] ? this.product[0].id_product : null
                        }
                    ]
                }
            }

            if (payload.filter.conditions.find(condition => !condition.value)) {
                return
            }

            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-history', { ...payload })
                if (res) {
                    this.approvals = res.data.rows
                    this.approvals.forEach((approval) => {
                        approval.date ? approval.date = moment(approval.date).format('DD/MM/YYYY HH:mm:ss') : false
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async listUserGroups () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_user",
                            operator: "=",
                            value: this.getUser.id_user
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipSecurity + 'user-group/list', { ...payload })
                if (res) {
                    this.userGroups = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getlevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_approval_flow_level",
                            operator: "=",
                            value: this.actualLevel.id_approval_flow_level
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'approval-flow-level/list', { ...payload })
                if (res) {
                    if (res.data.rows.length > 0) {
                        res.data.rows[0].change_values === 1 ? this.actualLevel.readOnly = false : this.actualLevel.readOnly = true
                    }
                    if (this.actualLevel.id_level > 0 && this.actualLevel.id_status !== 4){
                        this.readOnlyApproval = this.actualLevel.readOnly
                        this.readOnlyApproval === true ? this.$toast.warning('Este nível de aprovação não permite alterações') : false
                    } else {
                        this.levels.length > 0 ? this.readOnlyApproval = true : this.readOnlyApproval = false
                        if (this.readOnlyApproval) {
                            this.$toast.success('Este evento está com todos os níveis aprovados')
                        }
                    }
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        solver(column = 0, targetRow = 0, targetValue = 0, changeRow = 0){
            function ruleOf3 (initValChange, currTargetVal, initValTarget){
                if(initValTarget === 0 ) return 0
                return ( initValChange * currTargetVal ) / initValTarget
            }
            // TODO: ver com ademir se é assim que posso me referenciar a table, par apode referenciar as celulas
            // OK! isso aí, Luc.
            const table = this.hotInstance

            let initialValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
            let initialValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

            let calculatedChangeRow = ruleOf3( initialValueFromChangeRow , targetValue , initialValueFromTargetRow )
            if(!calculatedChangeRow) return { interations:0, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }

            table.setDataAtCell(changeRow, column, calculatedChangeRow)

            // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

            let condition = true
            let interations = 0

            while (condition) {
                interations++
                let newValueFromTargetRow =  parseFloat(table.getDataAtCell(targetRow, column))
                let newValueFromChangeRow =  parseFloat(table.getDataAtCell(changeRow, column))

                let dif = targetValue - newValueFromTargetRow

                if( Math.abs(dif) <= 0.5 ){
                    condition = false
                    table.setDataAtCell(changeRow, column, initialValueFromChangeRow)
                    return { interations, newValueFromChangeRow, newValueFromTargetRow }
                }
                // TODO: testar o algoritimo e ajustar os incrementos e decrementos
                if( dif > 0 && dif <= 1 ) newValueFromChangeRow = newValueFromChangeRow + 0.1
                if( dif > 1 ) newValueFromChangeRow = newValueFromChangeRow + 1
                if( dif < 0 && dif >= -1 ) newValueFromChangeRow = newValueFromChangeRow - 0.1
                if( dif < -1 ) newValueFromChangeRow = newValueFromChangeRow - 1

                table.setDataAtCell(changeRow, column, newValueFromChangeRow)
                // TODO: verificar se precisa chamar alguma função para reclacular as formulas da planilha e se é sincrono ou assincrono

                if(interations > 10000) condition = false
            }
            return { interations, newValueFromChangeRow: initialValueFromChangeRow , newValueFromTargetRow: initialValueFromTargetRow }
        },

        closeDialogApprovalAll () {
            if (this.doRefresh) {
                this.dialogApprovalAll = false
                this.doRefresh = false
                this.refresh()
            } else {
                this.dialogApprovalAll = false
            }
        },

        async approveAll (option) {
            const groupToApproval = this.selected.length > 0 ? this.selected : this.allActualLevels
            this.loadingApproval = true
            try {
                const payload = { items: [] }
                groupToApproval.forEach((product) => {
                    payload.items.push({
                        id_event: this.selectedEvent,
                        id_planning_group: this.planningGroup[0].id,
                        id_product: product.id_product,
                        justification: this.justification
                    })
                })
                if (option === 1) {
                    await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/approve', { ...payload })
                } else {
                    await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/disapprove', { ...payload })
                }
                this.$toast.success('Grupos de planejamento aprovados com successo')
            } catch (err) {
                this.$fnError(err)
            }
            this.loadingApproval = false
            this.justification = ''
            this.selected = []
            this.selectAllToApprove = false
            this.selectAllToReprove = false
            this.refresh()
        },

        async setAllLevels () {
            const payload = {
                filter: {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event',
                            operator: '=',
                            value: this.selectedEvent
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: this.selectedPlanningGroup
                        }
                    ]
                }
            }
            try {
                const res = await this.$http.post(this.$ipApprovalFlow + 'event-status-planning-group/list-levels', { ...payload })
                if (res.data.rows.length > 0) {
                    let levels = res.data.rows
                    let products = this.productOption.items.filter( product => product.id_planning_group === this.selectedPlanningGroup )
                    products = products.map(product => ({ ...product, levels: levels.filter( level => level.id_product === product.id_product) }))
                    products = products.map(product => ({ ...product, unapprovedLevels: product.levels.filter( level => level.id_status === 5 || !level.id_status) }))
                    products = products.map(product => ({
                        ...product,
                        // VERIFICA SE TEM NÍVEL POR APROVAR
                        actualLevel: product.unapprovedLevels.length > 0 ?
                            { ...product.unapprovedLevels[0], enable: this.userGroups.find(group => group.id_group === product.unapprovedLevels[0].id_group ) ? true : false } :
                            // VERIFICA SE TODOS NÍVEIS ESTÃO APROVADOS
                            product.levels.length > 0 ?
                                { ...product.levels[product.levels.length - 1], enable: this.userGroups.find(group => group.id_group === product.levels[product.levels.length - 1].id_group) ? true : false  } :
                                //NÃO TEM NÍVEL CADASTRADO
                                { approval_flow_level_description: 'Nenhum', enable: false }
                        //O ENABLE HABILITA OU DESABILITA BOTÃO DE APROVAR E DESAPROVAR
                }))
                this.allActualLevels = products
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async updateMonths () {
            const payload = {
                id_event: this.event.id,
            }
            try {
                this.loadingUpdateMonths = true
                const res = await this.$http.post(this.$ipPurchasePlanning + 'purchase-planning/copy-from-actual', { ...payload })
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.loadingUpdateMonths = false
                    this.updateMonthsDialog = false
                }
            } catch (err) {
                this.$fnError(err)
                this.loadingUpdateMonths = false
            }
            this.loadingUpdateMonths = false
            this.updateMonthsDialog = false
            this.refresh()
        },
    },
}
</script>

<style scoped>
div.v-tab {
    font-size: 12px;
}

div.v-tab.v-tab--active {
    font-weight: bold;
}

div.v-tab.v-tab--active:hover span {
    font-weight: bold;
    color: var(--primary-color);
}

div.v-tab:hover span {
    font-weight: bold;
    color: black;
}

.v-menu__content {
    max-width: 350px !important;
}
</style>

<style>
.v-dialog__content {
    z-index: 2000 !important;
}
</style>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>
