<template>
    <v-dialog v-model="itemDialog" max-width="900px" style="z-index: 2000 !important;"
        persistent
    >
        <v-card style="position: relative;">
            <v-card-title class="light--text"
                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
            >
                <span ref="formTitle" class="headline">{{ formTitle }}</span>

                <v-spacer></v-spacer>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on"
                            class="pa-0 mr-2"
                            min-width="48px"
                            text dark
                            @click="resetFields()"
                        >
                            <v-icon color="light">mdi-restart</v-icon>
                        </v-btn>
                    </template>
                    <span>Limpar campos</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-btn v-on="on"
                            class="pa-0"
                            @click="close"
                            min-width="48px"
                            text dark
                        >
                            <v-icon color="light">mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Fechar</span>
                </v-tooltip>
            </v-card-title>

            <v-card-text style="background: white; z-index: 2000 !important;">
                <v-container style="background: white; z-index: 2000 !important;">
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <template v-for="field in fields">
                                <v-text-field v-if="field.type === 'text' || field.type === 'number'"
                                    :type="field.type"
                                    :key="field.name"
                                    :label="field.required ? `${field.label} *` : field.label"
                                    v-model="field.value"
                                    :hint="field.hint ? field.hint : ''"
                                    :persistent-hint="field.hint ? true : false"
                                    :class="{'mb-4': field.hint}"
                                />

                                <v-autocomplete v-if="field.type === 'combo'"
                                    :key="field.name"
                                    v-model="field.value"
                                    :label="field.required ? `${field.label} *` : field.label"
                                    :items="field.items"
                                    item-value="text"
                                    return-object
                                    clearable
                                    :loading="field.loading"
                                    @change="updateItems(field)"
                                    :hint="field.hint ? field.hint : ''"
                                    :persistent-hint="field.hint ? true : false"
                                    :class="{'mb-4': field.hint}"
                                />

                                <v-menu v-if="field.type === 'date'"
                                    :key="field.name"
                                    :ref="`menu${field.ref}`"
                                    v-model="field.show"
                                    :close-on-content-click="false"
                                    :return-value.sync="field.value"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    style="z-index: 1500 !important;"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :value="formattedDate(field.value)"
                                            :label="field.label"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                            clearable
                                            @click:clear="clearDate(field)"
                                            style="min-width: 300px !important; z-index: 1000 !important;"
                                            :hint="field.hint ? field.hint : ''"
                                            :persistent-hint="field.hint ? true : false"
                                            :class="{'mb-4': field.hint}"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker color="primary"
                                        v-model="field.value"
                                        no-title
                                        scrollable
                                        style="z-index: 1500 !important;"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="field.show = false"
                                        >
                                            Cancelar
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="selectedDate(field)"
                                        >
                                            Confirmar
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                <v-divider class="mx-4"></v-divider>
                <v-card-actions class="px-6 py-6">
                    <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="close"
                        class="mr-4"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="save"
                        :disabled="emptyRequired"
                        :loading="saving"
                    >
                        Salvar
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
    props: {
        itemDialog: { type: Boolean, required: true },
        event: { type: Object, required: true },
        selectedPlanningGroup: { type: Number, required: true },
        selectedRow: { type: Object, required: false, default: () => {} },
        duplicatingItem: { type: Boolean, required: false, default: false }
    },

    data() {
        return {
            saving: false,
            totalContractValue: 0,

            fields: [
                {
                    name: 'id_vendor',
                    label: 'Fornecedor',
                    value: null,
                    required: true,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipVendor, 'vendor' ]
                },
                {
                    name: 'id_purchase_channel',
                    label: 'Canal de compras',
                    value: null,
                    required: true,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipPurchase, 'purchase-channel' ]
                },
                {
                    name: 'id_product',
                    label: 'Contrato',
                    value: null,
                    required: true,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipProduct, 'product-purchase-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event_type',
                            operator: '=',
                            value: this.event.id_event_type
                        },
                        {
                            AndOr: 'AND',
                            column: 'fiscal_year',
                            operator: '=',
                            value: this.event.fiscal_year
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: null,
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_vendor',
                            operator: '=',
                            value: null
                        },
                    ]
                },
                {
                    name: 'id_cost_center',
                    label: 'Centro de custo',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_cost_center_type',
                            operator: '=',
                            value: 1
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_company_group',
                            operator: '=',
                            value: this.event.id_company_group
                        }
                    ]
                },
                {
                    name: 'id_account_group',
                    label: 'Conta gerencial',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipAccount, 'account-group' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 6
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_account_group_version',
                            operator: '=',
                            value: this.event.id_account_group_version
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_sales_channel',
                            operator: 'IS',
                            value: null
                        },
                    ]
                },
                {
                    name: 'id_chart_account',
                    label: 'Conta contábil',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipAccount, 'chart-account-group' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_account_group',
                            operator: '=',
                            value: null
                        },
                    ]
                },
                {
                    name: 'id_employee',
                    label: 'Colaborador',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipOrganization, 'employee' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_employee_type',
                            operator: 'IN',
                            value: [3, 4]
                        }
                    ]
                },
                {
                    name: 'contract_percentage',
                    label: 'Percentual do contrato',
                    value: null,
                    required: false,
                    hint: 'Valor em porcentagem (%)',
                    type: 'number',
                },
                {
                    name: 'contract_price',
                    label: 'Preço do contrato',
                    value: null,
                    required: false,
                    type: 'number',
                },
                {
                    name: 'contract_quantity',
                    label: 'Quantidade contratada',
                    value: null,
                    required: false,
                    type: 'number',
                },
                {
                    name: 'contract_value',
                    label: 'Valor do contrato',
                    value: null,
                    required: false,
                    hint: 'Percentual x preço x quantidade',
                    type: 'number',
                },
                {
                    name: 'purchase_description',
                    label: 'MEMO',
                    value: null,
                    required: false,
                    type: 'text',
                },
                {
                    name: 'purchase_item_code',
                    label: 'Código do item',
                    value: null,
                    required: false,
                    type: 'text',
                },
                {
                    name: 'purchase_item_description',
                    label: 'Descrição do item',
                    value: null,
                    required: false,
                    type: 'text',
                },
                {
                    name: 'renewal_month',
                    label: 'Mês de renovação',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                },
                {
                    name: 'id_market_index',
                    label: 'Índice de reajuste',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipUnit, 'market-index' ]
                },
                {
                    name: 'start_date',
                    label: 'Início do contrato',
                    value: null,
                    required: false,
                    type: 'date',
                    show: false,
                    ref: 'start'
                },
                {
                    name: 'end_date',
                    label: 'Término do contrato',
                    value: null,
                    required: false,
                    type: 'date',
                    show: false,
                    ref: 'end'
                },
                {
                    name: 'id_payment_condition',
                    label: 'Condição de pagamento',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipPayment, 'payment-condition' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_payment_condition_type',
                            operator: '=',
                            value: 2,
                        }
                    ]
                },
                {
                    name: 'id_status',
                    label: 'Status',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipUnit, 'status' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_status_type',
                            operator: '=',
                            value: 15,
                        }
                    ]
                },
                {
                    name: 'id_currency',
                    label: 'Moeda',
                    value: null,
                    required: false,
                    type: 'combo',
                    items: [],
                    endpoint: [ Vue.prototype.$ipUnit, 'currency' ]
                },
            ],

            selectedEmployeeIsPJ: false,
            backupChartAccountItems: [],
        }
    },

    computed: {
        emptyRequired() {
            return !!this.fields.filter(f => f.required && !f.value).length
        },

        formTitle() {
            let formTitle = 'Novo item'
            if (this.selectedRow.id_event && !this.duplicatingItem) {
                formTitle = 'Editar item'
            }

            return formTitle
        },

        selectedCostCenter() {
            return this.fields.find(i => i.name === 'id_cost_center').value
        },

        selectedEmployee() {
            return this.fields.find(i => i.name === 'id_employee').value
        },

        selectedContractPercentage() {
            return this.fields.find(i => i.name === 'contract_percentage').value
        },

        selectedContractPrice() {
            return this.fields.find(i => i.name === 'contract_price').value
        },

        selectedContractQuantity() {
            return this.fields.find(i => i.name === 'contract_quantity').value
        },

        selectedAccountGroup() {
            return this.fields.find(i => i.name === 'id_account_group').value
        },

        chartAccountOption() {
            return this.fields.find(i => i.name === 'id_chart_account')
        },
    },

    watch: {
        selectedCostCenter: {
            immediate: true,
            handler(val) {
                let costCenterField = this.fields.find(i => i.name === 'id_cost_center')
                let accountGroupField = this.fields.find(i => i.name === 'id_account_group')
                let chartAccountField = this.fields.find(i => i.name === 'id_chart_account')
                
                if (val) {
                    costCenterField.required = true
                    accountGroupField.required = true
                    chartAccountField.required = true
                } else {
                    costCenterField.required = false
                    accountGroupField.required = false
                    chartAccountField.required = false
                }
            }
        },

        selectedEmployee: {
            immediate: true,
            handler(val) {
                this.selectedEmployeeIsPJ = false

                if (val) {
                    if (val.id_employee_type === 3 || val.id_employee_type === 4) {
                        this.selectedEmployeeIsPJ = true
                    }
                }
            }
        },

        selectedContractPercentage: {
            immediate: true,
            handler(val) {
                let percentage = 1
                if (val && this.selectedContractQuantity && this.selectedContractPrice) {
                    percentage = val / 100
                    let totalValue = parseFloat(this.selectedContractQuantity) * parseFloat(this.selectedContractPrice) * percentage
                    this.fields.find(i => i.name === 'contract_value').value = totalValue
                } else if (!val && this.selectedContractQuantity && this.selectedContractPrice) {
                    let totalValue = parseFloat(this.selectedContractQuantity) * parseFloat(this.selectedContractPrice) * percentage
                    this.fields.find(i => i.name === 'contract_value').value = totalValue
                }
            }
        },

        selectedContractPrice: {
            immediate: true,
            handler(val) {
                if (val && this.selectedContractQuantity) {
                    let percentage = 1
                    if (this.selectedContractPercentage) percentage = this.selectedContractPercentage / 100

                    let totalContractValue = parseFloat(val) * parseFloat(this.selectedContractQuantity)
                    this.totalContractValue = totalContractValue

                    let totalValue = totalContractValue * percentage
                    this.fields.find(i => i.name === 'contract_value').value = totalValue
                }
            }
        },

        selectedContractQuantity: {
            immediate: true,
            handler(val) {
                if (val && this.selectedContractPrice) {
                    let percentage = 1
                    if (this.selectedContractPercentage) percentage = this.selectedContractPercentage / 100

                    let totalContractValue = parseFloat(val) * parseFloat(this.selectedContractPrice)
                    this.totalContractValue = totalContractValue

                    let totalValue = totalContractValue * percentage
                    this.fields.find(i => i.name === 'contract_value').value = totalValue
                }
            }
        },

        totalContractValue: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.fields.find(i => i.name === 'contract_value').hint = `Percentual x preço x quantidade | Valor total: ${val}`
                }
            }
        },

        selectedAccountGroup: {
            immediate: true,
            handler(val) {
                this.backupChartAccountItems = []

                if (val) {
                    this.backupChartAccountItems = cloneDeep(this.chartAccountOption.items)
                    let filteredChartAccountItems = this.chartAccountOption.items.filter(i => i.id_account_group === this.selectedAccountGroup.id)
                    this.chartAccountOption.items = filteredChartAccountItems
                }
            }
        }
    },

    async created() {
        if (this.selectedRow.id_event) {
            // edit item

            let promises = []
            this.fields.filter(f => f.type === 'combo' && f.endpoint).forEach(f => {
                promises.push(new Promise((res, rej) => {
                    this.getItems(f)
                    .then(async (result) => {
                        res(result)
                    })
                    .catch((err) => {
                        rej(err)
                    })
                }))
            })

            const res = await Promise.all(promises)
            if (res) {
                for (let key in this.selectedRow) {
                    let index = this.fields.findIndex(f => f.name === key)
                    if (index > -1) {
                        if (this.fields[index].type === 'combo') {
                            if (this.fields[index].name === 'renewal_month') {
                                this.fields[index].value = this.selectedRow[key]
                            } else if (this.fields[index].name === 'id_chart_account') {
                                let found = this.fields[index].items.find(i => i.id_chart_account === this.selectedRow[key])
                                this.fields[index].value = found
                            } else if (this.fields[index].name === 'id_product') {
                                let found = this.fields[index].items.find(i => i.id_product === this.selectedRow[key])
                                this.fields[index].value = found
                            } else {
                                let found = this.fields[index].items.find(i => i.id === this.selectedRow[key])
                                this.fields[index].value = found
                            }
                        } else if (this.fields[index].type === 'date') {
                            if (!this.selectedRow[key]) {
                                this.fields[index].value = null
                            } else {
                                let date = this.selectedRow[key]
                                let year = date.slice(6, 10)
                                let month = date.slice(3, 5)
                                let day = date.slice(0, 2)
                                this.fields[index].value = `${year}-${month}-${day}`
                            }
                        } else {
                            if (this.fields[index].name === 'contract_percentage') {
                                this.fields[index].value = this.selectedRow[key] * 100
                            } else if (this.fields[index].name === 'contract_value') {
                                this.totalContractValue = this.selectedRow.value00
                                this.fields[index].value = this.totalContractValue
                            } else {
                                this.fields[index].value = this.selectedRow[key]
                            }
                        }
                    }
                }
            }
        } else {
            // new item

            this.resetFields()

            this.fields.forEach(f => {
                if (f.type === 'combo' && f.endpoint) {
                    this.getItems(f)
                }
            })
        }
    },

    methods: {
        async getItems(field) {
            field.loading = true

            let filter = { conditions: [] }
            if (field.conditions) {
                filter.conditions = field.conditions
            }

            if (field.name === 'id_product') {
                let planningGroupindex = field.conditions.findIndex(i => i.column === 'id_planning_group')
                if (this.selectedPlanningGroup) {
                    field.conditions[planningGroupindex].value = this.selectedPlanningGroup
                } else {
                    field.conditions[planningGroupindex].value = null
                }

                let vendorField = this.fields.find(i => i.name === 'id_vendor')
                let vendorIndex = field.conditions.findIndex(i => i.column === 'id_vendor')
                if (vendorField.value) {
                    field.conditions[vendorIndex].value = vendorField.value.id
                } else {
                    field.conditions[vendorIndex].value = null
                }
            }

            if (field.name === 'id_account_group') {
                let costCenterField = this.fields.find(i => i.name === 'id_cost_center')

                if (costCenterField.value) {
                    let index = field.conditions.findIndex(i => i.column === 'id_sales_channel')
                    if (costCenterField.value.id_sales_channel === 3) {
                        field.conditions[index].operator = '='
                        field.conditions[index].value = 3
                    } else {
                        field.conditions[index].operator = 'IS'
                        field.conditions[index].value = null
                    }
                }
            }

            if (field.name === 'id_chart_account') {
                let accountGroupField = this.fields.find(i => i.name === 'id_account_group')

                let index = field.conditions.findIndex(i => i.column === 'id_account_group')
                if (accountGroupField.value) {
                    field.conditions[index].value = accountGroupField.value.id
                } else {
                    field.conditions[index].value = null
                }
            }

            let payload = {
                filter: {
                    conditions: filter.conditions.filter(i => i.value || i.operator === 'IS')
                }
            }

            try {
                const res = await this.$http.post(`${field.endpoint[0]}${field.endpoint[1]}/list-options`, payload)
                if (res) {
                    field.items = res.data.rows
                    field.loading = false
                }
            } catch (err) {
                this.$fnError(err)
                field.loading = false
            }
        },

        updateItems(field) {
            if (field.name === 'id_vendor') {
                let productField = this.fields.find(i => i.name === 'id_product')
                if (productField) {
                    productField.loading = true
                    this.getItems(productField)
                }
            }

            if (field.name === 'id_cost_center') {
                let accountGroupField = this.fields.find(i => i.name === 'id_account_group')
                if (accountGroupField) {
                    accountGroupField.loading = true
                    this.getItems(accountGroupField)
                }
            }

            if (field.name === 'id_account_group') {
                let chartAccountField = this.fields.find(i => i.name === 'id_chart_account')
                if (chartAccountField) {
                    chartAccountField.loading = true
                    this.getItems(chartAccountField)
                }
            }
        },

        clearDate(field) {
            field.value = null
        },

        formattedDate(date) {
            return date ? moment.utc(date).format("DD/MM/YYYY") : null
        },

        selectedDate(field) {
            this.$refs[`menu${field.ref}`][0].save(field.value)
        },

        resetFields() {
            this.fields = this.fields.map(f => {
                return {
                    ...f,
                    value: null
                }
            })
        },

        close() {
            this.$emit('closeItemDialog')
        },

        async save() {
            this.saving = true

            let payload = this.selectedRow

            payload['id_event'] = this.event.id
            if (this.selectedRow.id_event && !this.duplicatingItem) {
                payload['id_purchase_planning'] = this.selectedRow.id_purchase_planning
            } else {
                payload['id_purchase_planning'] = null
            }

            this.fields.forEach(f => {
                if (f.type === 'combo') {
                    if (f.name === 'id_chart_account') {
                        return payload[f.name] = f.value ? f.value.id_chart_account : null
                    } else if (f.name === 'id_product') {
                        return payload[f.name] = f.value ? f.value.id_product : null
                    } else if (f.name === 'renewal_month') {
                        return payload[f.name] = f.value ? f.value : null
                    } else {
                        return payload[f.name] = f.value ? f.value.id : null
                    }
                }

                if (f.type === 'text' || f.type === 'number' || f.type === 'date') {
                    if (f.name === 'contract_value') {
                        payload.value00 = this.totalContractValue ? this.totalContractValue : null
                    }

                    if (f.name === 'contract_percentage') {
                        payload[f.name] = f.value / 100
                    } else {
                        return payload[f.name] = f.value
                    }
                }
            })

            try {
                const res = await this.$http.post(`${this.$ipPurchasePlanning}purchase-planning/save`, payload)
                if (res) {
                    this.$toast.success(res.data.msg)
                    this.$emit('closeItemDialog')
                    this.$emit('setRefresh')
                }
            } catch (err) {
                this.saving = false
                this.$fnError(err)
            }
        }
    }
}
</script>

<style>

</style>