<template>
    <div id="PurchasePlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGrid.vue'

export default {
    name:'PurchasePlanning',

    components:{
        ZnapHotGrid
    },

    data() {
        return {
            options: [
                {
                    column: 'id_event',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Evento',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipEvent, 'event' ]
                },
                {
                    column: 'id_planning_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Grupo de planejamento',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ]
                },
                {
                    column: 'id_vendor',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Fornecedor e CNPJ',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipVendor, 'vendor', '/list-options-purchase-planning' ]
                },
                {
                    column: 'id_product',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Contrato e código do contrato',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipProduct, 'product-purchase-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_event_type',
                            operator: '=',
                            value: null
                        },
                        {
                            AndOr: 'AND',
                            column: 'fiscal_year',
                            operator: '=',
                            value: null,
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_planning_group',
                            operator: '=',
                            value: null,
                        }
                    ],
                },
                {
                    column: 'id_employee',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Nome do colaborador',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipOrganization, 'employee' ]
                },
                {
                    column: 'id_cost_center',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Centro de custo',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_cost_center_type',
                            operator: '=',
                            value: 1
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_company_group',
                            operator: '=',
                            value: null,
                        }
                    ]
                },
                {
                    column: 'id_account_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta Gerencial',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipAccount, 'account-group' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 6
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_account_group_version',
                            operator: '=',
                            value: null,
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_sales_channel',
                            operator: 'IS',
                            value: null
                        },
                    ]
                },
                {
                    column: 'id_chart_account',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta Contábil',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipAccount, 'chart-account-group' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_account_group',
                            operator: '=',
                            value: null
                        },
                    ]
                },
                {
                    column: 'id_sales_channel',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Tipo de contrato',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-channel', 'list-options-purchase-planning' ]
                },
                {
                    column: 'id_sales_force',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Gerência executiva',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-purchase-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 6
                        }
                    ]
                },
                {
                    column: 'id_sales_force_bp',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'BP',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-purchase-planning' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_sales_force_type',
                            operator: '=',
                            value: 7,
                        }
                    ]
                },
                {
                    column: 'id_customer_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta cliente',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipCustomer, 'customer-group', 'list-options-purchase-planning' ]
                },
                {
                    column: 'purchase_item_code',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Descrição e ID dos itens',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipPurchasePlanning, 'purchase-item' ]
                },
                {
                    column: 'id_status',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Status',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipUnit, 'status' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_status_type',
                            operator: '=',
                            value: 15,
                        }
                    ]
                },
                {
                    column: 'renewal_month',
                    is: 'ComboBox',
                    items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    multiple: false,
                    label: 'Mês de renovação',
                    required: false,
                    filterable: true,
                    isDropdownOption: true
                },
            ]
        }
    },
}
</script>

<style scoped>
</style>